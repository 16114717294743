import * as actionName from './actionName'
import { getToken, getUserInfo } from '../utils/auth'

function formatRequestConfig(code, params) {
  const body = {
    'header': {
      'code': code
      // perms: '',// 菜单权限
      // url: url // 菜单地址
    },
    'body': formatData(code, params)
  }
  return body
}

function formatData(code, params) {
  var token = getToken()
  var userInfo = getUserInfo()
  params = params || {}
  switch (code) {
    // case actionName.HXCS_QY_QYXX: // 企业详细信息
    //   // eslint-disable-next-line no-case-declarations
    //   params = params || {}
    //   if (token) {
    //     params.token = token
    //   }
    //   break

    case actionName.HXCS_QY_OSST: // 获取阿里OOS上传参数
      break
    case actionName.HXCS_QY_QYYZYH: // 企业用户名验证
      if (userInfo) {
        params.companyId = userInfo
      }
      break
    // 暂时写死， 设备列表
    // case actionName.HXCS_CLI_SBLXLB:
    //   if (userInfo) {
    //     params.companyId = '111111111'
    //   }
    //   if (token) {
    //     params.token = token
    //   }
    //   break
    default:
      if (userInfo) {
        params.companyId = userInfo
      }
      if (token) {
        params.token = token
      }
      break
  }
  return params
}

function formatCommonData(code, params) {
  return {
    url: actionName.MAIN_URL,
    method: 'POST',
    data: code ? formatRequestConfig(code, params) : ''
  }
}

export default {
  formatData,
  formatCommonData
}
