const variable = {
  // 是否需要刷新活动列表
  isRefreshActiveInfo: false,
  // 优惠券可关联店铺是否要刷新
  isRefreshCouponAssociatedStore: false,
  // 字典是否要刷新
  // isRefreshDictionary: false,
  // 工单列表是否要刷新
  isRefreshWorkOrder: false,
  // 知识库列表是否要刷新
  isRefreshRepo: false,
  // 是否需要刷新用户数据
  // isRefreshCustomerData: false

  // 无权限访问的 企业id
  noAccessCompanyId: '201911274a3a420eb80e41ade8959451'
}

export default variable
