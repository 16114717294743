var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar }
      }),
      _vm._v(" "),
      _c("breadcrumb", { staticClass: "breadcrumb-container" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          _vm.isShowPay === "1"
            ? _c(
                "div",
                { staticClass: "header-menu" },
                [
                  _vm.curUser && !_vm.curUser.groupName
                    ? _c(
                        "el-menu",
                        {
                          staticClass: "el-menu-demo",
                          attrs: {
                            "default-active": _vm.activeIndex,
                            mode: "horizontal"
                          },
                          on: { select: _vm.handleSelect }
                        },
                        [
                          _c(
                            "el-menu-item",
                            { attrs: { index: "plus" } },
                            [
                              _c(
                                "router-link",
                                { attrs: { to: "/plus" } },
                                [
                                  _c("svg-icon", {
                                    staticClass: "plus",
                                    attrs: {
                                      "icon-class": "iconhuiyuanzhongxin"
                                    }
                                  }),
                                  _vm._v("\n            会员Plus\n          ")
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-dropdown",
            {
              staticClass: "avatar-container",
              attrs: { trigger: "click" },
              on: { command: _vm.showMoreItem }
            },
            [
              _c("div", { staticClass: "avatar-wrapper" }, [
                _c("span", { staticClass: "user-name" }, [
                  _vm._v(
                    "  " +
                      _vm._s(_vm.name || (_vm.curUser && _vm.curUser.groupName))
                  )
                ]),
                _vm._v(" "),
                _c("img", {
                  staticClass: "user-avatar",
                  attrs: { src: _vm.avatar + "?imageView2/1/w/80/h/80" }
                }),
                _vm._v(" "),
                _c("i", { staticClass: "el-icon-caret-bottom" })
              ]),
              _vm._v(" "),
              _c(
                "el-dropdown-menu",
                {
                  staticClass: "user-dropdown",
                  attrs: { slot: "dropdown" },
                  slot: "dropdown"
                },
                [
                  _vm.curUser && !_vm.curUser.groupName
                    ? _c(
                        "router-link",
                        { attrs: { to: "/" } },
                        [
                          _c("el-dropdown-item", [
                            _vm._v("\n            主页\n          ")
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-dropdown-item",
                    { attrs: { command: "modifyPassword" } },
                    [
                      _c("span", { staticStyle: { display: "block" } }, [
                        _vm._v("修改密码")
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-dropdown-item",
                    { attrs: { command: "versionNumber" } },
                    [
                      _c("span", { staticStyle: { display: "block" } }, [
                        _vm._v("版本:" + _vm._s(_vm.Version))
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-dropdown-item",
                    { attrs: { divided: "", command: "logout" } },
                    [
                      _c("span", { staticStyle: { display: "block" } }, [
                        _vm._v("退出登录")
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.curUser && !_vm.curUser.groupName
            ? _c(
                "el-dropdown",
                {
                  attrs: { trigger: "click" },
                  on: { command: _vm.goToMessage }
                },
                [
                  _c(
                    "el-badge",
                    {
                      staticClass: "item",
                      attrs: { value: _vm.unReadList.length }
                    },
                    [_vm._v("未读消息")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-dropdown-menu",
                    { attrs: { slot: "dropdown" }, slot: "dropdown" },
                    [
                      _c("el-dropdown-item", { attrs: { command: "order" } }, [
                        _vm._v(
                          "工单提醒（" +
                            _vm._s(_vm.unReadOrderList.length) +
                            "）"
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-dropdown-item",
                        { attrs: { command: "reminder" } },
                        [
                          _vm._v(
                            "催单提醒（" +
                              _vm._s(_vm.unReminderList.length) +
                              "）"
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-dropdown-item",
                        { attrs: { command: "information" } },
                        [
                          _vm._v(
                            "新业务咨询（" +
                              _vm._s(_vm.informationList.length) +
                              "）"
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-dropdown-item",
                        { attrs: { command: "sellPrice" } },
                        [
                          _vm._v(
                            "销售单价变更（" +
                              _vm._s(_vm.unSellPriceList.length) +
                              "）"
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-dropdown-item",
                        { attrs: { command: "applyBilling" } },
                        [
                          _vm._v(
                            "申请开票退回（" +
                              _vm._s(_vm.unApplyBillingList.length) +
                              "）"
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "修改密码",
            visible: _vm.dialogFormVisible,
            "append-to-body": true,
            "custom-class": "special-dialog"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              staticStyle: { width: "400px", "margin-left": "50px" },
              attrs: {
                rules: _vm.rules,
                model: _vm.temp,
                "label-position": "left",
                "label-width": "100px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "原始密码", prop: "oldPassWord" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入原始密码" },
                    model: {
                      value: _vm.temp.oldPassWord,
                      callback: function($$v) {
                        _vm.$set(_vm.temp, "oldPassWord", $$v)
                      },
                      expression: "temp.oldPassWord"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "新密码", prop: "passWord" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入新密码" },
                    model: {
                      value: _vm.temp.passWord,
                      callback: function($$v) {
                        _vm.$set(_vm.temp, "passWord", $$v)
                      },
                      expression: "temp.passWord"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "确认密码", prop: "repassWord" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入确认密码" },
                    model: {
                      value: _vm.temp.repassWord,
                      callback: function($$v) {
                        _vm.$set(_vm.temp, "repassWord", $$v)
                      },
                      expression: "temp.repassWord"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogFormVisible = false
                    }
                  }
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("table.cancel")) + "\n      "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.editPwd()
                    }
                  }
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("table.confirm")) + "\n      "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "版本记录",
            visible: _vm.versionNumberVisible,
            "append-to-body": true,
            "custom-class": "special-dialog version-dialog"
          },
          on: {
            "update:visible": function($event) {
              _vm.versionNumberVisible = $event
            }
          }
        },
        [
          _c("div", { staticClass: "version-container" }, [
            _c("h2", [_c("a", [_vm._v("最后打包：" + _vm._s(_vm.buildTime))])]),
            _vm._v(" "),
            _c("h2", [_c("a", [_vm._v("当前版本：" + _vm._s(_vm.Version))])]),
            _vm._v(" "),
            _c("h2", [_c("a", [_vm._v("版本历史：")])]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "version-scroll" },
              _vm._l(_vm.versionData, function(item, index) {
                return _c("div", { key: index, staticClass: "version-item" }, [
                  _c("p", [_c("b", [_vm._v("版本号：")])]),
                  _vm._v(" "),
                  _c("p", [_vm._v(_vm._s(item.Version))]),
                  _vm._v(" "),
                  _c("p", [_c("b", [_vm._v("更新日期：")])]),
                  _vm._v(" "),
                  _c("p", [_vm._v(_vm._s(item.date))]),
                  _vm._v(" "),
                  _c("p", [_c("b", [_vm._v("更新内容：")])]),
                  _vm._v(" "),
                  _c(
                    "ol",
                    _vm._l(item.VersionMessage, function(cItem, i) {
                      return _c("li", { key: i }, [
                        _vm._v(
                          "\n              " + _vm._s(cItem) + "\n            "
                        )
                      ])
                    }),
                    0
                  )
                ])
              }),
              0
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }