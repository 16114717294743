import { constantRoutes, getView } from '@/router'
import Layout from '@/layout'

/**
 * 处理菜单数据转成路由对象数组
 * @param menuList
 */
export function filterAsyncRoutes(menuList) {
  const res = []
  menuList.forEach(menu => {
    // 遍历根菜单
    res.push(filterAsyncRoutesMenu(menu))
  })
  return res
}

/**
 * 处理一个菜单的方法
 * @param menu
 * @returns {{path: *, component: (*), children: [], meta: {icon: (*|string), title: *}, name: *}}
 */
function filterAsyncRoutesMenu(menu) {
  const tmp = {
    path: menu.url,
    // component: menu.type === '1' ? Layout : getView(menu.url),
    name: menu.name,
    hidden: menu.type === '4',
    meta: {
      title: menu.name,
      // icon: menu.icon || 'icondef'
      icon: menu.icon
    },
    children: []
  }
  const { children } = menu
  const num = children.length
  if (num > 0) {
    // 有 children 的情况
    // 权限
    const perms = {}
    // 临时子菜单
    const child = []
    // 是否有新弹出窗口
    let isMenu = false
    for (let i = 0; i < num; i++) {
      const { type } = children[i]
      if (type === '3') {
        perms[children[i].perms] = true
      } else if (type === '2') {
        child.push(filterAsyncRoutesMenu(children[i]))
      } else if (type === '4') {
        isMenu = true
        perms[children[i].perms] = true
        child.push(filterAsyncRoutesMenu(children[i]))
      }
    }

    // 判断有子菜单
    if (child.length > 0) {
      if (menu.type !== '1') {
        tmp.name = menu.name + '_RouterMenu'
        tmp.component = getView('routerMenu')
        tmp.redirect = { name: menu.name }
      } else {
        tmp.component = Layout
      }
      // 判断是否有新弹出窗口
      if (isMenu) {
        // 有新窗口的，添加一个子菜单，再改变父菜单的跳
        child.push({
          path: '/',
          component: getView(menu.url),
          name: menu.name,
          // hidden: menu.type === '4',
          hidden: true,
          meta: {
            title: menu.name,
            // icon: menu.icon || 'icondef'
            icon: menu.icon
          },
          props: { perms }
        })
        delete tmp.name

        // tmp.redirect = '*'
      } else {
        tmp.redirect = menu.url + '/' + child[0].path
      }
      child.push({
        path: '*',
        name: menu.name + '_404',
        meta: {
          title: '404'
        },
        component: getView('/404'),
        hidden: true
      })
      tmp.children = child
    } else {
      // 无 children 的情况
      if (menu.type === '1') {
        delete tmp.meta.title
        delete tmp.name
        tmp.component = Layout
        tmp.children = [
          {
            path: menu.url,
            component: getView(menu.url),
            name: menu.name,
            meta: {
              title: menu.name,
              // icon: menu.icon || 'icondef'
              icon: menu.icon
            },
            props: { perms }
          },
          {
            path: '*',
            name: menu.name + '404',
            meta: {
              title: '404'
            },
            component: getView('/404'),
            hidden: true
          }

        ]
      } else {
        tmp.component = getView(menu.url)
        tmp.props = { perms }
      }
    }
  } else {
    // 无 children 的情况
    if (menu.type === '1') {
      delete tmp.meta.title
      delete tmp.name
      tmp.component = Layout
      tmp.children = [
        {
          path: menu.url,
          component: getView(menu.url),
          name: menu.name,
          meta: {
            title: menu.name,
            // icon: menu.icon || 'icondef'
            icon: menu.icon
          }
        },
        {
          path: '*',
          name: menu.name + '404',
          meta: {
            title: '404'
          },
          component: getView('/404'),
          hidden: true
        }

      ]
    } else {
      tmp.component = getView(menu.url)
    }
  }
  return tmp
}

const state = {
  routes: [],
  addRoutes: []
}

const mutations = {
  SET_ROUTES: (state, routes) => {
    state.addRoutes = routes
    state.routes = constantRoutes.concat(routes)
  }
}

const actions = {
  generateRoutes({ commit }, menuList) {
    return new Promise(resolve => {
      const accessedRoutes = filterAsyncRoutes(menuList)
      commit('SET_ROUTES', accessedRoutes)
      resolve(accessedRoutes)
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
