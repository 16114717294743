export const Version = '1.08.25'
export const VersionRecord = [
  {
    Version: '1.08.25',
    date: '2020/11/25',
    VersionMessage: ['新增服务团队可通过注册手机号登录自有管理页面', '新增服务团队页面接单，二次派单工程师或直接提交关单报告', '新增已完成工单回访功能', '修正不同阶段下工单状态值']
  },

  {
    Version: '1.07.17',
    date: '2020/11/17',
    VersionMessage: ['可设置特定产品分类是否显示在报修小程序选项中', '修正服务团队管理上线后部分BUG', '修正工单批量导出时部分数据缺失问题']
  },
  {
    Version: '1.06.11',
    date: '2020/11/11',
    VersionMessage: ['运维组模块升级为服务团队管理', '不同服务团队可自行创建所属工程师并分派工单', '服务团队可通过手机登录后进行业务管理']
  },
  {
    Version: '1.05.01',
    date: '2020/11/01',
    VersionMessage: ['资产列表新增按客户编码搜索', '新增产品分类管理', '客户批量导入时可预分组（组织结构）']
  }
]
