<template>
  <div id="app">
    <div
      v-if="$route.path !== '/login'"
      class="com-mark"
    />
    <router-view />
  </div>
</template>

<script>
  /* import { removeWatermark, setWaterMark } from '@/utils/waterMark' */
  /* import { getOtherData } from '@/utils/auth'
  const otherData = getOtherData() */
  import { addWaterMark } from '@/utils/index'
  export default {
    name: 'App',
    watch: {
      /* '$store.getters.name': {
        handler(val) {
          console.log(this.$route.path)
          if (val) {
            addWaterMark(
              '.com-mark',
              `${val}`
            )
            console.log(1)
            return
          } else {
            console.log(2)
            return
          }
        }
      } */
      '$route.path': {
        handler(val) {
          if (val !== '/login') {
            setTimeout(() => {
              /* console.log(otherData, this.$store.getters.name) */
              addWaterMark(
                '.com-mark',
                `${this.$store.getters.name ? this.$store.getters.name : this.$store.getters.groupName}`
              )
            }, 200)
          }
        }
      }
    }
  }
</script>

<style lang="scss">
.com-mark {
  position: fixed;
  z-index: 99999;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  pointer-events: none;
}
.el-table--border td{border-left:none;border-right:none;border-top:none;}
.el-table--border .el-table__row:last-child td{border-bottom:none;}
.el-table--border{ border-bottom: 1px solid #dfe6ec !important; }
::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #fff;
}
::-webkit-scrollbar-thumb {
    border-radius:6px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, .08);
    background-color: rgba(0, 0, 0, .08)
}
.list-header-right{
  height: 40px;
}
.fixed-header2 {
  .tags-view-container{
    display: none;
  }
  & + .app-main{
    height: calc(100vh - 50px) !important;
  }
}
</style>
