var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { padding: "0 15px 0 0" }, on: { click: _vm.toggleClick } },
    [
      _c("svg-icon", {
        staticClass: "hamburger",
        class: { "is-active": _vm.isActive },
        attrs: { "icon-class": "icondaohangzhankai" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }