import Vue from 'vue'
// import SvgIcon from '@/components/SvgIcon'// svg component
import SvgIcon from '@/components/SvgIcon/iconfontSvg'// svg component

// register globally
Vue.component('svg-icon', SvgIcon)
// 因为使用的 iconfont 的图标 所以下面注释了
// const req = require.context('./svg', false, /\.svg$/)
// const requireAll = requireContext => requireContext.keys().map(requireContext)
// requireAll(req)
