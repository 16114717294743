<template>
  <div class="sidebar-logo-container" :class="{'collapse':collapse}">
    <!--    <transition name="sidebarLogoFade">-->
    <!--      <router-link v-if="collapse" key="collapse" class="sidebar-logo-link" to="/companyMap">-->
    <div v-if="collapse" key="collapse" class="sidebar-logo-link">
      <img v-if="logo" :src="logoUrl ? logoUrl : logo" class="sidebar-logo">
    </div>
    <!--      </router-link>-->
    <!--      <router-link v-else key="expand" class="sidebar-logo-link" to="$route.fullPath">-->
    <div v-else key="expand" class="sidebar-logo-link">
      <img v-if="logo" :src="logoUrl ? logoUrl : logo" class="sidebar-logo">
    </div>

    <!--      </router-link>-->
    <!--    </transition>-->
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  const logo = require('../../../assets/logo/logo.png')
  export default {
    name: 'SidebarLogo',
    props: {
      collapse: {
        type: Boolean,
        required: true
      }
    },
    data() {
      return {
        title: '大象企服后台管理',
        logo: logo
      }
    },
    computed: {
      ...mapGetters([
        'logoUrl'
      ])
    }
  }
</script>

<style lang="scss" scoped>
  @import '../../../styles/variables.scss';
  .sidebarLogoFade-enter-active {
    transition: opacity 1.5s;
    background: $subMenuBg;
  }

  .sidebarLogoFade-enter,
  .sidebarLogoFade-leave-to {
    opacity: 0;
  }

  .sidebar-logo-container {
    position: relative;
    width: 100%;
    height: 50px;
    line-height: 50px;
    text-align: center;
    overflow: hidden;

    & .sidebar-logo-link {
      height: 100%;
      width: 100%;
      text-align: center;
      margin-top:4px;

      & .sidebar-logo {
        /*height: 20px;*/
        vertical-align: middle;
      }

      & .sidebar-title {
        display: inline-block;
        margin: 0;
        color: #fff;
        font-weight: 600;
        line-height: 50px;
        font-size: 14px;
        font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
        vertical-align: middle;
      }
    }

    &.collapse {
      .sidebar-logo {
        margin-right: 0px;
      }
    }
  }
  .hideSidebar .sidebar-logo-link{
    img{
      width:58px;
    }
  }
</style>
