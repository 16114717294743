// 服务商相关接口
// 服务商—-用户登录
export const HXCS_FW_FWDL = 'HXCS_FW_FWDL'
// 服务商—-用户列表信息
export const HXCS_FW_YHLB = 'HXCS_FW_YHLB'
// 服务商—-新增或修改用户信息
export const HXCS_FW_BJYH = 'HXCS_FW_BJYH'
// 服务商—-服务商信息编辑
export const HXCS_FW_FWBJ = 'HXCS_FW_FWBJ'
// 服务商—-服务商信息读取
export const HXCS_FW_FWXX = 'HXCS_FW_FWXX'
// 服务商—-工单列表信息
export const HXCS_FW_GDLB = 'HXCS_FW_GDLB'
// 服务商—-发送短信验证码
export const HXCS_FW_FSDX = 'HXCS_FW_FSDX'
// 服务商—-工单接取信息
export const HXCS_FW_GDJQ = 'HXCS_FW_GDJQ'
// 服务商--工单拒绝
export const HXCS_FW_GDJJ = 'HXCS_FW_GDJJ'
// 服务商—-工单报告提交信息
export const HXCS_FW_GDBG = 'HXCS_FW_GDBG'
// 服务商—-工单统计
export const HXCS_FW_SYGDTJ = 'HXCS_FW_SYGDTJ'
// 服务商—-任务工单列表
export const HXCS_FW_RWGDL = 'HXCS_FW_RWGDL'
// 服务商—-任务工单接取信息
export const HXCS_FW_RWGDQ = 'HXCS_FW_RWGDQ'
// 服务商—-任务工单拒接
export const HXCS_FW_RWGDJ = 'HXCS_FW_RWGDJ'
// 服务商—-任务工单保存信息
export const HXCS_FW_RWGDB = 'HXCS_FW_RWGDB'
