exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n", ""]);

// exports
exports.locals = {
	"menuText": "#fff",
	"menuActiveText": "#fff",
	"subMenuActiveText": "#f4f4f5",
	"menuBg": "#2e323f",
	"menuHover": "#5584ff",
	"subMenuBg": "#2e323f",
	"subMenuHover": "#5584ff",
	"sideBarWidth": "200px",
	"primaryButtonBg": "#5584ff",
	"primaryButtonHoverBg": "#3F72F7",
	"tableHeaderBg": "#ebecf0",
	"appMainBg": "#2e323f",
	"mainBg": "#5584ff",
	"grayTextColor": "#c0c4cc",
	"grayDeepTextColor": "#fff",
	"tagViewBg": "#eef3f9",
	"tagViewText": "#5584ff",
	"breadLine": "#fff",
	"lineIco": "#fff"
};