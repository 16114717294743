/**
 * 数据本地化的核心
 */
import { CACHE_DATA_NAME } from '../actionName'

const cacheData = {}

export default function cache(param, service) {
  const code = param.data.header.code
  for (const itemName in CACHE_DATA_NAME) {
    const item = CACHE_DATA_NAME[itemName]
    if (item.getCode === code) {
      return getcacheData(itemName, item, service).getAllItem(param)
    }
    if (item.editCode === code) {
      return getcacheData(itemName, item, service).setItem(param)
    }
    if (item.delCode === code) {
      return getcacheData(itemName, item, service).delItem(param)
    }
  }
  return service(param)
}

/**
 * 返回缓存对象
 * @param name
 * @param setParam
 * @param service
 * @returns {CacheCore|*}
 */
function getcacheData(name, setParam, service) {
  if (cacheData[name]) {
    return cacheData[name]
  } else {
    const cache = new CacheCore(setParam, service)
    cacheData[name] = cache
    return cache
  }
}

/**
 * 清除全部接口缓存数据
 */
export function clearAllCache() {
  for (const itemName in cacheData) {
    delete cacheData[itemName]
  }
}

/**
 * 本地缓存数据的核心模块
 */
class CacheCore {
  constructor(param, service) {
    // 接口参数
    // 查询接口
    // this.getCode = param.getCode || null
    // // 修改接口
    // this.editCode = param.editCode || null
    // // 删除接口
    // this.delCode = param.delCode || null
    // 返回数据时候的数组路径，因为有肯呢个数据结果的结构可能不一样需要这个参数调整一下
    this.arrayPath = param.arrayPath || null
    // 总数据存储
    this.customerData = null
    // 是否正在请求接口
    this.isLoading = false
    // 数据是否需要更新
    this.isUpdate = false
    // 唯一对应key，修改删除条目会本地更新
    this.matchKey = param.matchKey || null
    // 请求方法
    this.service = service
  }

  /**
   * 获取全部数据内容
   * @returns {Promise<*>}
   */
  async getAllItem(param) {
    await this._check(param)
    return this.customerData
  }

  /**
   * 根据多个参数 查询到的多个匹配的内容，返回数组
   * @returns {Promise<*>}
   */
  async queryItems(query) {
    if (typeof query === 'object') {
      return this.customerData.filter((item) => {
        for (const q in query) {
          if (item[q] !== query[q]) {
            return false
          }
        }
        return true
      })
    }
    // eslint-disable-next-line no-throw-literal
    throw { err: 'getItems()方法需要一个对象的参数' }
  }

  /**
   * 新增或者修改项，如果匹配上了则直接修改本地数据
   * @param param 要更新的数据
   * @returns {Promise<*>}
   */
  async setItem(param) {
    // if (!this.editCode) {
    //   console.log('wwwlin-没有editCode。所以没有办法更新或添加数据')
    //   // eslint-disable-next-line no-throw-literal
    //   throw '没有editCode。所以没有办法更新或添加数据'
    // }
    const that = this
    return this.service(param).then((resultData) => {
      if (resultData) {
        let newdata = null
        // 找到对应的数据更新里面的数据
        if (that.matchKey) {
          const paramData = param.data.body
          newdata = that.findData(that.customerData, (item) => {
            return item[that.matchKey] && item[that.matchKey] === paramData[that.matchKey]
          })
          if (newdata) {
            Object.assign(newdata, paramData, resultData)
          } else {
            // 更新数据
            that.isUpdate = true
          }
        } else {
          // 更新数据
          that.isUpdate = true
        }
        return newdata || resultData
      }
    })
  }

  findData(customerData, findFun) {
    const len = customerData.length
    for (let i = 0; i < len; i++) {
      const item = customerData[i]
      if (findFun(item)) {
        return item
      }
      if (item.children) {
        const find = this.findData(item.children, findFun)
        if (find) {
          return find
        }
      }
    }
  }

  /**
   *  删除项，如果匹配上了则直接修改本地数据
   *  删除接口在正确删除后回返回1 ，不成功回返回其他数字，具体看接口文档。
   * @param param 要更新的数据
   * @returns {Promise<*>}
   */
  async delItem(param) {
    // if (!this.delCode) {
    //   console.log('wwwlin-delCode。所以没有办法删除')
    //   // eslint-disable-next-line no-throw-literal
    //   throw 'delCode。所以没有办法删除'
    // }
    const that = this
    return this.service(param).then((resultData) => {
      // 这里由于删除接口的原因：删除成功 返回1，其他的
      if (resultData === '1') {
        // 找到对应的数据然后删除掉
        if (that.matchKey) {
          const paramData = param.data.body
          const len = that.customerData.findIndex((item) => {
            return item[that.matchKey] === paramData[that.matchKey]
          })
          that.customerData.splice(len, 1)
        } else {
          // 更新数据
          that.isUpdate = true
        }
      }
      return resultData
    })
  }

  // ///////////////////////////////////////////////
  /**
   * 检查是否有值或者是否需要刷新
   * @returns {Promise<boolean>}
   * @private
   */
  async _check(param) {
    const that = this
    if (this.isLoading) {
      await new Promise((resolve) => {
        const time = setInterval(() => {
          if (!that.isLoading) {
            clearInterval(time)
            resolve()
          }
        }, 200)
      })
      return true
    }
    if (this.customerData === null || this.isUpdate) {
      this.isLoading = true
      await this.service(param).then(response => {
        that.customerData = that.arrayPath ? response[that.arrayPath] : response
      })
      that.isUpdate = false
      that.isLoading = false
      return true
    }
    return true
  }
}
