import Vue from 'vue'

import Cookies from 'js-cookie'

import 'normalize.css/normalize.css' // A modern alternative to CSS resets

import Element from 'element-ui'
import './styles/element-variables.scss'

import '@/styles/index.scss' // global css

//  根据不同的打包命令引用不同的样式表
if (process.env.VUE_APP_COMPANYID === 'HDL') {
  require('./styles/layoutHDL.scss')
} else {
  require('./styles/layout.scss')
}

import App from './App'
import store from './store'
import router from './router'

import i18n from './lang' // internationalization
import '@/icons' // icon
import '@/permission' // permission control
import './utils/error-log' // error log

import './utils/drawer-drag'

import * as filters from './filters' // global filters
import global from './utils/global' // 一个全局用的变量对象
import echarts from 'echarts'
Vue.prototype.$echarts = echarts

import globalUploadImage from './components/GlobalUploadImage/index'
Vue.use(globalUploadImage)
/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online! ! !
 */
// import { mockXHR } from '../mock'
//
// if (process.env.NODE_ENV === 'production') {
//   mockXHR()
// }

Vue.use(Element, {
  size: Cookies.get('size') || 'medium', // set element-ui default size
  i18n: (key, value) => i18n.t(key, value)
})

// register global utility filters
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

Vue.config.productionTip = false

Vue.prototype.$global = global

new Vue({
  el: '#app',
  router,
  store,
  i18n,
  render: h => h(App)
})
