const companyBrowserTitle = {
  HDL: '优海企业信息化系统',
  SELEAD: 'ServiceLead'
}
const value = process.env.VUE_APP_COMPANYID
module.exports = {

  title: value ? companyBrowserTitle[value] : 'ServiceLead',

  /**
   * @type {boolean} true | false
   * @description Whether need tagsView
   */
  tagsView: true,

  /**
   * @type {boolean} true | false
   * @description Whether fix the header
   */
  fixedHeader: false,

  /**
   * @type {boolean} true | false
   * @description Whether show the logo in sidebar
   */
  sidebarLogo: true,

  /**
   * @type {string | array} 'production' | ['production', 'development']
   * @description Need show err logs component.
   * The default is only used in the production env
   * If you want to also use it in dev, you can pass ['production', 'development']
   */
  errorLog: 'production'
}
