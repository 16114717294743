/**
 * Created by PanJiaChen on 16/11/18.
 */
import { RELATIVE_PATH_URL } from './actionName'
import { isNumber } from 'lodash'
import * as math from 'mathjs'

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string}
 */
export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
      time = parseInt(time)
    }
    if ((typeof time === 'number') && (time.toString().length === 10)) {
      time = time * 1000
    }
    date = new Date(time)
  }

  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value]
    }
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }
    return value || 0
  })
  return time_str
}

/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
export function formatTime(time, option) {
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000
  } else {
    time = +time
  }
  const d = new Date(time)
  const now = Date.now()

  const diff = (now - d) / 1000

  if (diff < 30) {
    return '刚刚'
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前'
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前'
  } else if (diff < 3600 * 24 * 2) {
    return '1天前'
  }
  if (option) {
    return parseTime(time, option)
  } else {
    return (
      d.getMonth() +
      1 +
      '月' +
      d.getDate() +
      '日' +
      d.getHours() +
      '时' +
      d.getMinutes() +
      '分'
    )
  }
}

export function relativePathCompletion(url) {
  if (url.indexOf('https://')) {
    return url
  } else {
    return RELATIVE_PATH_URL + url
  }
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function param2Obj(url) {
  const search = url.split('?')[1]
  if (!search) {
    return {}
  }
  return JSON.parse(
    '{"' +
    decodeURIComponent(search)
      .replace(/"/g, '\\"')
      .replace(/&/g, '","')
      .replace(/=/g, '":"')
      .replace(/\+/g, ' ') +
    '"}'
  )
}
/**
 * @param {data} Array
 * @returns {Array}
 */
function deleteDeepChildren(data, field) {
  data.forEach((item) => {
    if (item[field] && item[field].length > 0) {
      deleteDeepChildren(item[field], field)
    } else {
      delete item[field]
    }
  })
}
export function deleteArrayEmptyChildren(data, field) {
  data && data.length > 0 && data.forEach((item) => {
    if (item[field] && item[field].length > 0) {
      deleteDeepChildren(item[field], field)
    } else {
      delete item[field]
    }
  })
}
/**
 * @param {dataArr} Array
 * @param {value} String || Number
 * @returns {string}
 */
function handleDeep(dataArr, value) {
  let valueData
  for (const i in dataArr) {
    const item = dataArr[i]
    if (item.value === value) {
      valueData = item
      break
    }
    if (item.children && item.children.length > 0) {
      valueData = handleDeep(item.children, value)
      if (valueData) break
    }
  }
  return valueData
}
export function matchDictionaryName(dataArr, value) {
  let valueData
  if (value || value === 0) {
    const __value = isNumber(value) ? String(value) : value
    for (const i in dataArr) {
      const item = dataArr[i]
      if (item.value === __value || String(item.value) === __value) {
        valueData = item
        break
      }
      if (item.children && item.children.length > 0) {
        valueData = handleDeep(item.children, __value)
        if (valueData) break
      }
    }
  }
  if (valueData) {
    return valueData.name || valueData.label
  }
  return '--'
}
/**
 * @param {showTime} Boolean
 * @returns {string}
 */
export function getNowTime(showTime) {
  const nowdata = new Date()
  const year = nowdata.getFullYear()
  let month = nowdata.getMonth()
  let date = nowdata.getDate()
  month = month + 1
  month = month.toString().padStart(2, '0')
  date = date.toString().padStart(2, '0')
  const hh = nowdata.getHours()
  const mm = nowdata.getMinutes()
  const ss = nowdata.getSeconds()
  if (showTime) {
    return `${year}-${month}-${date} ${hh}:${mm}:${ss}`
  }
  return `${year}-${month}-${date}`
}

export const imgType = ['HEIC', 'heic', 'JPG', 'png', 'jpg', 'psd', 'jpeg', 'bmp', 'gif', 'webp']
export const videoType = ['mp4', 'mov', '3gp', 'mpv']

export function fileSuffixName(fileName, mark) {
  const index = fileName.lastIndexOf(mark)
  return fileName.substring(index + 1, fileName.length)
}
export function getFileSuffixNameFromUrl(url) {
  const __url = fileSuffixName(url, '/')
  const typeText = __url && fileSuffixName(__url, '.').toLowerCase()
  return typeText
}
export function parseAttachmentFiles(dataArr) {
  const __imgFiles = []
  const __preveiewList = []
  const __videoFiles = []
  const __pdfFiles = []
  dataArr && dataArr.length > 0 && dataArr.forEach(fItem => {
    if (!fItem.url) return
    const typeText = getFileSuffixNameFromUrl(fItem.url)
    if (imgType.findIndex(item => item === typeText) !== -1) {
      fItem.type = 'img'
      __imgFiles.push(fItem)
      __preveiewList.push(fItem.url)
    } else if (videoType.findIndex(item => item === typeText) !== -1) {
      fItem.type = 'video'
      __videoFiles.push(fItem)
    } else {
      fItem.type = 'pdf'
      __pdfFiles.push(fItem)
    }
  })
  return {
    imgFiles: __imgFiles,
    preveiewList: __preveiewList,
    videoFiles: __videoFiles,
    pdfFiles: __pdfFiles
  }
}

export function handleDistrictData(data) {
  // const newData = []
  // const provinceData = data[0]
  // const cityData = data[1]
  // const countyData = data[2]
  // provinceData.forEach(item => {
  //   newData.push({
  //     value: item.id,
  //     label: item.fullname,
  //     children: []
  //   })
  // })

  // cityData.forEach(item => {
  //   const cityCode = item.id.substr(4, 2)
  //   const code = item.id.substr(2, 2)
  //   const index = newData.findIndex(item2 => item2.value.substr(0, 2) === item.id.substr(0, 2))
  //   if (cityCode !== '00') {
  //     const index1 = newData[index]['children'].findIndex(item3 => item3.label === newData[index]['label'])
  //     if (index1 !== -1) {
  //       newData[index]['children'][index1]['children'].push({
  //         value: item.id,
  //         label: item.fullname
  //       })
  //     } else {
  //       newData[index]['children'].push({
  //         value: newData[index].value,
  //         label: newData[index].label,
  //         children: []
  //       })
  //       newData[index]['children'][newData[index]['children'].length - 1]['children'].push({
  //         value: item.id,
  //         label: item.fullname
  //       })
  //     }
  //   } else if (code === '90') {
  //     const index2 = newData[index]['children'].findIndex(item3 => item3.label === '直辖县')
  //     if (index2 !== -1) {
  //       newData[index]['children'][index2]['children'].push({
  //         value: item.id,
  //         label: item.fullname
  //       })
  //     } else {
  //       newData[index]['children'].unshift({
  //         value: '直辖县',
  //         label: '直辖县',
  //         children: []
  //       })
  //       newData[index]['children'][1]['children'].push({
  //         value: item.id,
  //         label: item.fullname
  //       })
  //     }
  //   } else {
  //     newData[index]['children'].push({
  //       value: item.id,
  //       label: item.fullname,
  //       children: []
  //     })
  //   }
  // })

  // countyData.forEach(item => {
  //   const startCode = item.id.substr(0, 2)
  //   const endCode = item.id.substr(2, 2)
  //   const index1 = newData.findIndex(item => item.value.substr(0, 2) === startCode)
  //   const index2 = newData[index1]['children'].findIndex(item => item.value.substr(2, 2) === endCode)
  //   newData[index1]['children'][index2]['children'].push({
  //     value: item.id,
  //     label: item.fullname
  //   })
  // })

  // return newData

  let list = [], obj = {} // eslint-disable-line
  data.forEach((arr, index) => {
    arr.forEach(item => {
      // let res = index == 0 ? list : obj[item.id.substr(0, index * 2)].children
      const res = index === 0 ? list : (obj[item.id.substr(0, index * 2)].children || (obj[item.id.substr(0, index * 2)].children = []))
      res.push(
        obj[item.id.substr(0, index * 2 + 2)] = {
          value: item.fullname,
          label: item.fullname
        }
      )
    })
  })

  obj = null
  return list
}

export const customMoney = (s) => {
  if (s == null) return ''
  s = String(s)
  const isNegatives = s[0] === '-'
  if (isNegatives) {
    s = s.substr(1)
  }
  const l = s.split('.')[0].split('').reverse()
  const r = s.split('.')[1] ? s.split('.')[1] : null
  let t = ''
  for (let i = 0; i < l.length; i++) {
    t += l[i] + ((i + 1) % 3 === 0 && (i + 1) !== l.length ? ',' : '')
  }
  return (isNegatives ? '-' : '') + t.split('').reverse().join('') + (r ? `.${r}` : '')
}

// 添加水印
export const addWaterMark = (ele, str) => {
  const body = document.querySelector(ele)
  const canvas = document.createElement('canvas')

  body.appendChild(canvas)

  canvas.width = 200
  canvas.height = 100
  canvas.style.display = 'none'
  var cans = canvas.getContext('2d')
  cans.rotate((-20 * Math.PI) / 180)
  cans.font = '16px Microsoft JhengHei'
  cans.fillStyle = 'rgba(17, 17, 17, 0.2)'
  cans.textAlign = 'left'
  cans.textBaseline = 'Middle'
  cans.fillText(str, 30, 105, 200)
  body.style.backgroundImage = 'url(' + canvas.toDataURL('image/png') + ')'
}
export const printFn = (value) => {
  const precision = 14
  return Number(math.format(value, precision))
}
// 数据格式化
export const number_format = (number, decimals, dec_point, thousands_sep) => {
  /*
   * 参数说明：
   * number：要格式化的数字
   * decimals：保留几位小数
   * dec_point：小数点符号
   * thousands_sep：千分位符号
   * */
  number = (number + '').replace(/[^0-9+-Ee.]/g, '')
  var n = !isFinite(+number) ? 0 : +number
  var prec = !isFinite(+decimals) ? 2 : Math.abs(decimals)
  var sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep
  var dec = (typeof dec_point === 'undefined') ? '.' : dec_point
  var s = ''
  var toFixedFix = function(n, prec) {
    var k = Math.pow(10, prec)
    return '' + printFn(Math.ceil(printFn(n * k)) / k)
  }

  s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.')
  var re = /(-?\d+)(\d{3})/
  while (re.test(s[0])) {
    s[0] = s[0].replace(re, '$1' + sep + '$2')
  }

  if ((s[1] || '').length < prec) {
    s[1] = s[1] || ''
    s[1] += new Array(prec - s[1].length + 1).join('0')
  }
  return s.join(dec)
}
// 获取当年第一天到今天
export function getDateTime() {
  const nowdata = new Date()
  const year = nowdata.getFullYear() // 年份
  let month = nowdata.getMonth()
  let date = nowdata.getDate()
  month = month + 1
  month = month.toString().padStart(2, '0')
  date = date.toString().padStart(2, '0')
  return [`${year}-01-01`, `${year}-${month}-${date}`]
}
// js 日期加天数得到新的日期
export function DateAdd(days) {
  let sdate = new Date()
  sdate = sdate.valueOf()
  sdate = sdate + days * 24 * 60 * 60 * 1000
  sdate = new Date(sdate)
  const year = sdate.getFullYear()// 年
  const month = (sdate.getMonth() + 1) < 10 ? '0' + (sdate.getMonth() + 1) : (sdate.getMonth() + 1) // 月
  const day = sdate.getDate() < 10 ? '0' + sdate.getDate() : sdate.getDate() // 天
  var returnDate = year + '-' + month + '-' + day
  return returnDate
}

// 时间戳转化年月日
export function timestampToTime(value, type) {
  var time = new Date(value)
  var year = time.getFullYear()
  var month = time.getMonth() + 1
  var date = time.getDate()
  var hour = time.getHours()
  var minute = time.getMinutes()
  var second = time.getSeconds()
  month = month < 10 ? '0' + month : month
  date = date < 10 ? '0' + date : date
  hour = hour < 10 ? '0' + hour : hour
  minute = minute < 10 ? '0' + minute : minute
  second = second < 10 ? '0' + second : second
  var arr = [
    year + '-' + month + '-' + date,
    year + '-' + month + '-' + date + ' ' + hour + ':' + minute + ':' + second,
    year + '年' + month + '月' + date,
    month + '月' + date + '日' + ' ' + hour + ':' + minute,
    hour + ':' + minute + ':' + second
  ]
  return arr[type]
}
// 计算两个时间戳的差值
export function get_time_diff(time) {
  var diff = ''
  var time_diff = new Date().getTime() - time
  // 计算相差天数
  var days = Math.floor(time_diff / (24 * 3600 * 1000))

  // 计算相差小时数
  var leave1 = time_diff % (24 * 3600 * 1000)
  var hours = Math.floor(leave1 / (3600 * 1000))
  if (days > 0) {
    diff += days + ' 天'
    return diff
  } else if (hours > 0) {
    /* // 计算相差小时数
        var leave1 = time_diff % ( 24 * 3600 * 1000);
        var hours = Math.floor(leave1 / (3600 * 1000)); */
    if (hours > 0) {
      diff += hours + ' 小时'
    } else {
      if (diff !== '') {
        diff += hours + ' 小时'
      }
    }
    return diff
  } else {
    // 计算相差分钟数
    var leave2 = leave1 % (3600 * 1000)
    var minutes = Math.floor(leave2 / (60 * 1000))
    if (minutes > 0) {
      diff += minutes + ' 分钟'
    } else {
      if (diff !== '') {
        diff += minutes + ' 分钟'
      }
    }
    return diff
  }
  // 计算相差秒数
  /* var leave3 = leave2%(60*1000);
    var seconds = Math.round(leave3/1000);
    if (seconds > 0) {
        diff += seconds + '秒';
    } else {
        if (diff !== '') {
            diff += seconds + '秒';
        }
    } */
}
