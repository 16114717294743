import router from './router'
import store from './store'
import { Message } from 'element-ui'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { getToken, getViewTags } from './utils/auth' // get token from cookie
import getPageTitle from '@/utils/get-page-title'

NProgress.configure({ showSpinner: false }) // NProgress Configuration

const whiteList = ['/login', '/register', '/passwordBack', '/resetPassword'] // no redirect whitelist

let curAuditState = ''

router.beforeEach(async(to, from, next) => {
  // start progress bar
  NProgress.start()
  // set page title
  document.title = getPageTitle(to.meta.title)

  // determine whether the user has logged in
  const hasToken = getToken()
  if (hasToken) {
    if (to.path === '/login') {
      // if is logged in, redirect to the home page
      next({ path: '/' })
      NProgress.done()
    } else {
      if (store.getters.roles) {
        next()
      } else {
        try {
          const getUserInfos = await store.dispatch('user/getUserInfoAny')
          // if (getUserInfos.auditState !== curAuditState) {
          const { auditState } = getUserInfos
          if (auditState === '1' && curAuditState !== auditState) {
            curAuditState = auditState
            // 审核中
            next('/upapprove')
          } else if (auditState === '3' && curAuditState !== auditState) {
            curAuditState = auditState
            // 不通过  重新提交信息
            next('/settingSysRe')
          } else if (auditState === '2' && curAuditState !== auditState) {
            curAuditState = auditState
            // 登录成功
            // 获取用户权限下的菜单列表
            const menuList = await store.dispatch('user/getMenuList')
            // 处理菜单数据转换成路由数据
            const accessRoutes = await store.dispatch('permission/generateRoutes', menuList)
            // 在现有路由添加新的规则
            router.addRoutes(accessRoutes)

            const views = getViewTags()
            if (views) {
              store.dispatch('tagsView/setAllView', views)
            }

            if (to.redirectedFrom && to.redirectedFrom !== '/404') {
              next(to.redirectedFrom)
            } else if (accessRoutes.length > 0) {
              // 直接跳转有权限的第一个地址
              next(accessRoutes[0].path)
            } else if (to.path === '/404') {
              // 这里处理了如果还没获得权限信息 就会默认跳404，获取权限后再尝试404之前的链接
              next('/')
            } else {
              next({ ...to, replace: true })
            }
          } else {
            next()
          }
        } catch (error) {
          // remove token and go to login page to re-login
          await store.dispatch('user/resetToken')
          Message.error(error || 'Has Error')
          // next(`/login?redirect=${to.path}`)
          next(`/login`)
          NProgress.done()
        }
      }
    }
  } else {
    curAuditState = ''
    /* has no token*/
    if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly
      next()
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      next(`/login?redirect=${to.path}`)
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})
