const getters = {
  sidebar: state => state.app.sidebar,
  language: state => state.app.language,
  device: state => state.app.device,
  visitedViews: state => state.tagsView.visitedViews,
  cachedViews: state => state.tagsView.cachedViews,
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  name: state => state.user.name,
  groupName: state => state.user.groupName,
  isShowPay: state => state.user.isShowPay,
  expireTime: state => state.user.expireTime,
  logoUrl: state => state.user.logoUrl,
  roles: state => state.user.roles,
  permission_routes: state => state.permission.routes,
  permission_addRoutes: state => state.permission.addRoutes,
  errorLogs: state => state.errorLog.logs,
  companyId: state => state.user.userInfo
}
export default getters
