exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".navbar{background:#fff}.sidebar-logo-container{background:#fff}.tags-view-container{background:#fff}.dialog-main{padding-left:12px}#app .sidebar-container{background:#fff}#app .sidebar-container .el-menu .el-submenu .el-submenu__title:hover{background-color:rgba(85,132,255,0.1) !important;color:#5584FF !important}#app .sidebar-container .el-menu .el-submenu .el-submenu__title i{color:#333}#app .sidebar-container .el-menu .submenu-title-noDropdown.is-active{background-color:rgba(85,132,255,0.1) !important}#app .sidebar-container .el-menu .submenu-title-noDropdown:hover{background-color:rgba(85,132,255,0.1) !important;color:#5584FF !important}#app .sidebar-container .el-menu .nest-menu .el-menu-item.is-active{background-color:rgba(85,132,255,0.1) !important}#app .sidebar-container .el-menu .nest-menu .el-menu-item:hover{color:#5584FF !important;background-color:rgba(85,132,255,0.1) !important}.fixed-header{background:#FAFAFA}.app-main{background:#FAFAFA}.navbar .hamburger-container{color:#666}.el-breadcrumb__inner,.el-breadcrumb__inner a{color:#333}.navbar .right-menu .header-menu .el-menu-item{color:#606266}.navbar .right-menu .header-menu .plus{color:#606266}.navbar .right-menu .avatar-container .avatar-wrapper .user-name{color:#606266}.fixed-header .right-menu .el-badge{color:#606266}.fixed-header .tags-view-container .tags-view-wrapper .tags-view-item.active{background:rgba(85,132,255,0.1);color:#5584ff;border-bottom:4px solid #5584ff}.dashboard-container{padding:12px}\n", ""]);

// exports
exports.locals = {
	"menuText": "#333",
	"menuActiveText": "#5584FF",
	"subMenuActiveText": "#5584FF",
	"menuBg": "#fff",
	"menuHover": "rgba(85,132,255,0.1)",
	"subMenuBg": "#fff",
	"subMenuHover": "rgba(85,132,255,0.1)",
	"sideBarWidth": "200px",
	"primaryButtonBg": "#5584ff",
	"primaryButtonHoverBg": "#3F72F7",
	"tableHeaderBg": "#FAFAFA",
	"appMainBg": "#FAFAFA",
	"mainBg": "#5584ff",
	"grayTextColor": "#909399",
	"grayDeepTextColor": "#606266",
	"tagViewBg": "rgba(85,132,255,0.1)",
	"tagViewText": "#5584ff",
	"breadLine": "#333",
	"lineIco": "#666",
	"margin4": "4px"
};