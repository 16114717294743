import { setViewTags } from '../../utils/auth'
const state = {
  visitedViews: [],
  // 设置默认的缓存页面
  cachedViews: [],
  // 设置默认不缓存的页面
  // Redirect是刷新用的 不需要缓存
  excludeViews: ['Redirect'],
  cache: null
}

const mutations = {
  SET_ALL_VISITED_VIEW: (state, views) => {
    state.visitedViews.push(...views)
  },
  ADD_VISITED_VIEW: (state, view) => {
    const i = state.visitedViews.findIndex(v => v.path === view.path)
    if (i > -1) {
      state.visitedViews.splice(i, 1, Object.assign({}, view, {
        title: view.meta.title || 'no-name'
      }))
    } else {
      state.visitedViews.push(
        Object.assign({}, view, {
          title: view.meta.title || 'no-name'
        })
      )
    }
    // 写缓存
    setViewTags(state.visitedViews)
  },
  ADD_CACHED_VIEW: (state, view) => {
    // 添加不缓存的页面
    if (view.meta.noCache) {
      state.excludeViews.push(view.name)
    }
  },
  DEL_VISITED_VIEW: (state, view) => {
    for (const [i, v] of state.visitedViews.entries()) {
      if (v.path === view.path) {
        state.visitedViews.splice(i, 1)
        break
      }
    }
    // 写缓存
    setViewTags(state.visitedViews)
  },
  DEL_CACHED_VIEW: (state, view) => {
    const { cache, keys } = state.cache
    const path = view.path
    // 清除页面缓存
    const c = cache[path]
    if (c) {
      c.componentInstance.$destroy()
      delete cache[path]
    }
    const index = keys.indexOf(path)
    if (index > -1) {
      keys.splice(index, 1)
    }
  },

  DEL_OTHERS_VISITED_VIEWS: (state, view) => {
    state.visitedViews = state.visitedViews.filter(v => {
      return v.meta.affix || v.path === view.path
    })
  },

  DEL_OTHERS_CACHED_VIEWS: (state, view) => {
    const { cache, keys } = state.cache
    for (const key in cache) {
      if (key !== view.path) {
        cache[key].componentInstance.$destroy()
        delete cache[key]
        const index = keys.indexOf(key)
        if (index > -1) {
          keys.splice(index, 1)
        }
      }
    }
  },

  DEL_ALL_VISITED_VIEWS: state => {
    // keep affix tags
    const affixTags = state.visitedViews.filter(tag => tag.meta.affix)
    state.visitedViews = affixTags
    // 写缓存
    setViewTags(state.visitedViews)
  },
  DEL_ALL_CACHED_VIEWS: state => {
    const { cache, keys } = state.cache
    for (const key in cache) {
      cache[key].componentInstance.$destroy()
      delete cache[key]
      const index = keys.indexOf(key)
      if (index > -1) {
        keys.splice(index, 1)
      }
    }
  },

  UPDATE_VISITED_VIEW: (state, view) => {
    for (let v of state.visitedViews) {
      if (v.path === view.path) {
        v = Object.assign(v, view)
        break
      }
    }
    // 写缓存
    setViewTags(state.visitedViews)
  },
  SET_CACHE: (state, cache) => {
    state.cache = cache
  }
}

const actions = {
  // 设置全部标签
  setAllView({ commit }, view) {
    commit('SET_ALL_VISITED_VIEW', view)
  },
  // 增加标签
  addView({ dispatch }, view) {
    dispatch('addVisitedView', view)
    dispatch('addCachedView', view)
  },
  // 增加显示的标签
  addVisitedView({ commit }, view) {
    commit('ADD_VISITED_VIEW', view)
  },
  // 增加缓存的标签
  addCachedView({ commit }, view) {
    commit('ADD_CACHED_VIEW', view)
  },
  // 设置缓存的方法
  setCache({ commit }, cache) {
    commit('SET_CACHE', cache)
  },
  delView({ dispatch, state }, view) {
    return new Promise(resolve => {
      dispatch('delVisitedView', view)
      dispatch('delCachedView', view)
      resolve({
        visitedViews: [...state.visitedViews],
        cachedViews: [...state.cachedViews]
      })
    })
  },
  delVisitedView({ commit, state }, view) {
    return new Promise(resolve => {
      commit('DEL_VISITED_VIEW', view)
      resolve([...state.visitedViews])
    })
  },
  delCachedView({ commit, state }, view) {
    return new Promise(resolve => {
      commit('DEL_CACHED_VIEW', view)
      resolve([...state.cachedViews])
    })
  },

  delOthersViews({ dispatch, state }, view) {
    return new Promise(resolve => {
      dispatch('delOthersVisitedViews', view)
      dispatch('delOthersCachedViews', view)
      resolve({
        visitedViews: [...state.visitedViews],
        cachedViews: [...state.cachedViews]
      })
    })
  },
  delOthersVisitedViews({ commit, state }, view) {
    return new Promise(resolve => {
      commit('DEL_OTHERS_VISITED_VIEWS', view)
      resolve([...state.visitedViews])
    })
  },
  delOthersCachedViews({ commit, state }, view) {
    return new Promise(resolve => {
      commit('DEL_OTHERS_CACHED_VIEWS', view)
      resolve([...state.cachedViews])
    })
  },

  delAllViews({ dispatch, state }) {
    return new Promise(resolve => {
      dispatch('delAllVisitedViews')
      dispatch('delAllCachedViews')
      resolve({
        visitedViews: [...state.visitedViews],
        cachedViews: [...state.cachedViews]
      })
    })
  },
  delAllVisitedViews({ commit, state }) {
    return new Promise(resolve => {
      commit('DEL_ALL_VISITED_VIEWS')
      resolve([...state.visitedViews])
    })
  },
  delAllCachedViews({ commit, state }) {
    return new Promise(resolve => {
      commit('DEL_ALL_CACHED_VIEWS')
      resolve([...state.cachedViews])
    })
  },

  updateVisitedView({ commit }, view) {
    commit('UPDATE_VISITED_VIEW', view)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
