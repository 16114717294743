<template>
  <div class="navbar">
    <hamburger :is-active="sidebar.opened" class="hamburger-container" @toggleClick="toggleSideBar" />

    <breadcrumb class="breadcrumb-container" />

    <div class="right-menu">
      <!-- 添加头部菜单 -->
      <div v-if="isShowPay === '1'" class="header-menu">
        <el-menu v-if="curUser && !curUser.groupName" :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
          <!-- <el-menu-item index="home">
            首页
          </el-menu-item>
          <el-menu-item index="msg">
            消息
          </el-menu-item>
          <el-menu-item index="help">
            帮助
          </el-menu-item> -->
          <el-menu-item index="plus">
            <router-link to="/plus">
              <svg-icon icon-class="iconhuiyuanzhongxin" class="plus" />
              会员Plus
            </router-link>
          </el-menu-item>
        </el-menu>
      </div>
      <!--      <template v-if="device!=='mobile'">-->
      <!--        <lang-select class="right-menu-item hover-effect" />-->
      <!--      </template>-->

      <el-dropdown class="avatar-container" trigger="click" @command="showMoreItem">
        <div class="avatar-wrapper">
          <span class="user-name">  {{ name || (curUser && curUser.groupName) }}</span>
          <img :src="avatar+'?imageView2/1/w/80/h/80'" class="user-avatar">
          <i class="el-icon-caret-bottom" />
        </div>
        <el-dropdown-menu slot="dropdown" class="user-dropdown">
          <router-link v-if="curUser && !curUser.groupName" to="/">
            <el-dropdown-item>
              主页
            </el-dropdown-item>
          </router-link>
          <el-dropdown-item command="modifyPassword">
            <span style="display:block;">修改密码</span>
          </el-dropdown-item>
          <el-dropdown-item command="versionNumber">
            <span style="display:block;">版本:{{ Version }}</span>
          </el-dropdown-item>
          <el-dropdown-item divided command="logout">
            <span style="display:block;">退出登录</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <el-dropdown v-if="curUser && !curUser.groupName" trigger="click" @command="goToMessage">
        <el-badge :value="unReadList.length" class="item">未读消息</el-badge>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="order">工单提醒（{{ unReadOrderList.length }}）</el-dropdown-item>
          <el-dropdown-item command="reminder">催单提醒（{{ unReminderList.length }}）</el-dropdown-item>
          <el-dropdown-item command="information">新业务咨询（{{ informationList.length }}）</el-dropdown-item>
          <el-dropdown-item command="sellPrice">销售单价变更（{{ unSellPriceList.length }}）</el-dropdown-item>
          <el-dropdown-item command="applyBilling">申请开票退回（{{ unApplyBillingList.length }}）</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <el-dialog
      title="修改密码"
      :visible.sync="dialogFormVisible"
      :append-to-body="true"
      custom-class="special-dialog"
    >
      <el-form
        ref="dataForm"
        :rules="rules"
        :model="temp"
        label-position="left"
        label-width="100px"
        style="width: 400px; margin-left:50px;"
      >
        <el-form-item label="原始密码" prop="oldPassWord">
          <el-input v-model="temp.oldPassWord" placeholder="请输入原始密码" />
        </el-form-item>
        <el-form-item label="新密码" prop="passWord">
          <el-input v-model="temp.passWord" placeholder="请输入新密码" />
        </el-form-item>
        <el-form-item label="确认密码" prop="repassWord">
          <el-input v-model="temp.repassWord" placeholder="请输入确认密码" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">
          {{ $t('table.cancel') }}
        </el-button>
        <el-button type="primary" @click="editPwd()">
          {{ $t('table.confirm') }}
        </el-button>
      </div>
    </el-dialog>
    <!-- 版本号 -->
    <el-dialog
      title="版本记录"
      :visible.sync="versionNumberVisible"
      :append-to-body="true"
      custom-class="special-dialog version-dialog"
    >
      <div class="version-container">
        <h2>
          <a>最后打包：{{ buildTime }}</a>
        </h2>
        <h2>
          <a>当前版本：{{ Version }}</a>
        </h2>
        <h2>
          <a>版本历史：</a>
        </h2>
        <div class="version-scroll">
          <div v-for="(item, index) in versionData" :key="index" class="version-item">
            <p><b>版本号：</b></p>
            <p>{{ item.Version }}</p>
            <p><b>更新日期：</b></p>
            <p>{{ item.date }}</p>
            <p><b>更新内容：</b></p>
            <ol>
              <li v-for="(cItem,i) in item.VersionMessage" :key="i">
                {{ cItem }}
              </li>
            </ol>
          </div>
        </div>

      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import Breadcrumb from '@/components/Breadcrumb'
  import Hamburger from '@/components/Hamburger'
  // import LangSelect from '@/components/LangSelect'
  import elDragDialog from '@/directive/el-drag-dialog'
  import commonService from '@/utils/commonService'
  import * as actionName from '@/utils/actionName'
  import request from '@/utils/request'
  import { removeToken, removeUserInfo, getOtherData } from '@/utils/auth'
  import { Version, VersionRecord } from '@/utils/version'
  import { parseTime } from '@/utils'
  /* import { removeWatermark } from '@/utils/waterMark' */
  // import variable from '../../utils/global'
  // 打包日期
  const buildTime = parseTime(actionName.BUILD_TIME, '')
  export default {
    components: {
      Breadcrumb,
      Hamburger
      // LangSelect
    },
    directives: { elDragDialog },

    data() {
      const validateRepsw = (rule, value, callback) => {
        if (value !== this.temp.passWord) {
          callback(new Error('确认密码和输入密码不一致'))
        } else {
          callback()
        }
      }
      return {
        temp: {
          oldPassWord: '',
          passWord: ''
        },
        rules: {
          oldPassWord: [{ required: true, message: '请输入原始密码', trigger: 'blur' }],
          passWord: [{
            required: true,
            message: '请输入新密码',
            trigger: 'blur'
          }],
          repassWord: [{
            required: true,
            trigger: 'blur',
            validator: validateRepsw
          }]
        },
        versionRules: [],
        versionData: VersionRecord,
        dialogFormVisible: false,
        activeIndex: 'home',
        companyId: null,
        noAccessCId: null,
        isShowPay: localStorage.getItem('isShowPay'),
        unReadList: [],
        unReadOrderList: [], // 工单消息
        unReminderList: [], // 催单消息
        informationList: [], // 新业务咨询消息
        unSellPriceList: [], // 销售单价变更消息
        unApplyBillingList: [], // 申请开票退回
        timer: null,
        Version: Version,
        buildTime: buildTime, // 打包时间
        versionNumberVisible: false,
        curUser: getOtherData()
      }
    },
    computed: {
      ...mapGetters([
        'sidebar',
        'avatar',
        'device',
        'name',
        'groupName'
        // 'isShowPay'
      ])
    },
    created() {
      // this.companyId = getUserInfo()
      // this.noAccessCId = variable.noAccessCompanyId
      this.getUnreadMessageList()
      this.refreshMessage()
    },
    destroyed() {
      clearInterval(this.timer)
    },
    methods: {
      goToMessage(commond) {
        let type
        if (commond === 'reminder') {
          //    催单
          type = '2'
          this.$router.push({
            path: '/workorderManagement/workorderManagement',
            query: { urgeState: '0' }
          })
        }
        if (commond === 'order') {
          type = '1'
          this.$router.push({
            path: '/workorderManagement/workorderManagement',
            query: { msgReadState: '0' }
          })
        }
        if (commond === 'information') {
          type = '3'
          this.$router.push({
            path: '/businessConsult',
            query: { msgReadState: '0' }
          })
        }
        if (commond === 'sellPrice') {
          type = '4'
          this.$router.push({
            path: '/messageManage',
            query: { type: '15' }
          })
        }
        if (commond === 'applyBilling') {
          type = '5'
          this.$router.push({
            path: '/messageManage',
            query: { type: '16' }
          })
        }
        setTimeout(() => {
          this.handleOrderSetRead(type)
        }, 300)
      },
      // 将消息设置已读
      handleOrderSetRead(type) {
        const requestMenu = commonService.formatCommonData(actionName.HXCS_QY_YDXX, {
          type: type
        })
        request(requestMenu).then(response => {
          if (response === '1') {
            this.getUnreadMessageList()
          }
        })
      },
      getUnreadMessageList() {
        //  获取未读消息列表
        const requestMenu = commonService.formatCommonData(actionName.HXCS_QY_WDXXLB)
        request(requestMenu).then((data) => {
          const _unReadOrderList = []
          const _unReminderList = []
          const _informationList = []
          const _unSellPriceList = []
          const _unApplyBillingList = []
          data && data.length > 0 && data.forEach((item) => {
            if (item.type === '1') {
              _unReadOrderList.push(item)
              return
            }
            if (item.type === '2') {
              _unReminderList.push(item)
              return
            }
            if (item.type === '3') {
              _informationList.push(item)
            }
            if (item.type === '4') {
              _unSellPriceList.push(item)
              return
            }
            if (item.type === '5') {
              _unApplyBillingList.push(item)
            }
          })
          this.unReadOrderList = _unReadOrderList
          this.unReminderList = _unReminderList
          this.informationList = _informationList
          this.unSellPriceList = _unSellPriceList
          this.unApplyBillingList = _unApplyBillingList
          this.unReadList = data
        })
      },
      refreshMessage() {
        if (this.timer) {
          clearInterval(this.timer)
        } else {
          const that = this
          that.timer = setInterval(() => {
            that.getUnreadMessageList()
          }, 60000)
        }
      },
      handleSelect(key, keyPath) {
        // console.log(key, keyPath)
      },
      toggleSideBar() {
        this.$store.dispatch('app/toggleSideBar')
      },
      showMoreItem(command) {
        if (command === 'logout') {
          this.logout()
        }
        if (command === 'modifyPassword') {
          this.dialogFormVisible = true
        }
        if (command === 'versionNumber') {
          //   版本号
          this.versionNumberVisible = true
        }
      },
      async logout() {
        await this.$store.dispatch('user/logout')
        await this.$store.dispatch('tagsView/delAllViews')
        // this.$router.push(`/login?redirect=${this.$route.fullPath}`)
        /* removeWatermark() */
        this.$router.push(`/login`)
      },
      // 修改密码
      async editPwd() {
        const commitInfo = {
          oldPassWord: this.temp.oldPassWord,
          passWord: this.temp.passWord
        }
        let __actionName = actionName.HXCS_QY_YHMM
        let params = commitInfo
        if (this.curUser && this.curUser.groupName) {
          // 服务商修改密码
          __actionName = actionName.HXCS_FW_FWMMXG
          params = {
            oldPassWord: this.temp.oldPassWord,
            newPassWord: this.temp.passWord,
            groupName: this.curUser.groupName
          }
        }
        const requestMenu = commonService.formatCommonData(__actionName, params)
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
            request(requestMenu).then((data) => {
              if (data === '1') {
                removeToken()
                removeUserInfo()
                this.dialogFormVisible = false
                this.$notify({
                  title: '成功',
                  message: '操作成功',
                  type: 'success',
                  duration: 2000
                })
                this.$router.push(`/login?redirect=${this.$route.fullPath}`)
              } else if (data === '2') {
                this.$notify({
                  title: '错误',
                  message: '老密码输入错误',
                  type: 'error',
                  duration: 2000
                })
              }
            })
          }
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '../../styles/variables.scss';
  .navbar {
    height: 50px;
    overflow: hidden;
    position: relative;

    box-shadow: 0 1px 4px rgba(0, 21, 41, .08);
    .hamburger-container {
      line-height: 46px;
      height: 100%;
      float: left;
      cursor: pointer;
      transition: background .3s;
      -webkit-tap-highlight-color: transparent;
      padding-left: 10px;
      &:hover {
        background: rgba(0, 0, 0, .025)
      }
    }

    .breadcrumb-container {
      float: left;
    }
    .right-menu {
      float: right;
      height: 100%;
      line-height: 50px;
      &:focus {
        outline: none;
      }

      .header-menu{
        float: left;
        margin-right: 60px;
        .el-menu{
          background: none;
          .el-menu-item{
            &:hover{
              background: none;
            }
          }

        }
        .plus{
          font-size: 12px;
          padding-bottom: 2px;
        }
        .el-menu-item{
          width: 90px;

          /*background: #2e323f;*/
          height: 50px;
          line-height: 50px;
          border: none;
        }
    }

      .right-menu-item {
        display: inline-block;
        padding: 0 8px;
        height: 100%;
        font-size: 18px;
        color: #5a5e66;
        vertical-align: text-bottom;

        &.hover-effect {
          cursor: pointer;
          transition: background .3s;

          &:hover {
            background: rgba(0, 0, 0, .025)
          }
        }
      }

      .avatar-container {
        margin-right: 30px;

        .avatar-wrapper {
          position: relative;
          .user-name{

            vertical-align: 14px;
            padding-right: 5px;
          }
          .user-avatar {
            cursor: pointer;
            width: 40px;
            height: 40px;
            border-radius: 10px;
          }

          .el-icon-caret-bottom {
            cursor: pointer;
            position: absolute;
            right: -20px;
            top: 25px;
            font-size: 12px;
          }
        }
      }
    }
  }
  .el-badge{
    margin-right: 30px;
    display: inline-block;
    vertical-align: 13px;
    line-height: 32px;
    cursor: pointer;
  }

  .version-dialog{
    .version-container{
      padding: 0 24px 24px;
      .current-version{
        font-size: 16px;
        font-weight: 600;
        padding-bottom: 16px;
      }
      .version-record{
        font-size: 16px;
        padding-top: 10px;
      }
      .version-scroll{
        max-height: 500px;
        overflow-y: auto;
        margin-top: -14px;
        ul{
          list-style: none;
        }
      }
      .version-item{
        border-top: 1px dashed #bdbdbd;
        margin-top: 20px;
      }
    }
    .el-dialog__body {
      .el-form-item{
        margin-bottom: 0;
      }

    }
    .dialog-section{
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

</style>
