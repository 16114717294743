<template>
  <div :class="{'has-logo':showLogo}">
    <logo v-if="showLogo" :collapse="isCollapse" />
    <el-scrollbar wrap-class="scrollbar-wrapper">
      <el-menu
        :default-active="activeMenu"
        :collapse="isCollapse"
        :background-color="variables.menuBg"
        :text-color="variables.menuText"
        :unique-opened="true"
        :active-text-color="variables.menuActiveText"
        :collapse-transition="false"
        mode="vertical"
        @open="showMoreMenu"
      >
        <sidebar-item v-for="route in permission_routes" :key="route.path" :item="route" :base-path="route.path" />
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import Logo from './Logo'
  import SidebarItem from './SidebarItem'
  import variables from '@/styles/variables.scss'
  import variablesHDL from '@/styles/variablesHDL.scss'

  export default {
    components: { SidebarItem, Logo },
    computed: {
      ...mapGetters([
        'sidebar',
        'permission_routes'
      ]),
      // routes() {
      //   return this.$router.options.routes
      // },
      activeMenu() {
        const route = this.$route
        const { meta, path } = route
        // if set path, the sidebar will highlight the path you set
        if (meta.activeMenu) {
          return meta.activeMenu
        }
        return path
      },
      showLogo() {
        return this.$store.state.settings.sidebarLogo
      },
      variables() {
        if (process.env.VUE_APP_COMPANYID === 'HDL') {
          return variablesHDL
        }
        return variables
      },
      isCollapse() {
        return !this.sidebar.opened
      }
    },
    methods: {
      showMoreMenu(index) {
        this.$router.push({ path: index })
      }
    }
  }
</script>
