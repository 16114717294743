import Cookies from 'js-cookie'
import EDstr from './encrypt'

const TokenKey = 'hxcs_temp_token'
const UserInfo = 'hxcs_temp_uinfo'
const OtherKey = 'hxcs_temp_other'
const viewTags = 'hxcs_temp_viewTags'

let myToken = ''
let myUserInfo = ''
let otherData = {}
// 获取一些本地cookies*******************************

// token
export function getToken() {
  if (myToken === '') {
    myToken = EDstr.Decrypt(Cookies.get(TokenKey))
    return myToken
  } else {
    return myToken
  }
}

// 用户的信息
export function getUserInfo() {
  if (myUserInfo === '') {
    myUserInfo = EDstr.Decrypt(Cookies.get(UserInfo))
    return myUserInfo
  } else {
    return myUserInfo
  }
}

// 其他的数据
export function getOtherData() {
  if (JSON.stringify(otherData) === '{}' && Cookies.get(OtherKey)) {
    otherData = JSON.parse(Cookies.get(OtherKey))
    return otherData
  } else {
    return otherData
  }
}

// 打开的标签页缓存
export function getViewTags() {
  const views = Cookies.get(viewTags)
  if (views) {
    return JSON.parse(views)
  } else {
    return []
  }
}

// 设置***********************************************

// 设置 token
export function setToken(token, other) {
  myToken = token
  otherData = Object.assign(otherData, other)
  Cookies.set(OtherKey, otherData)
  return Cookies.set(TokenKey, EDstr.Encrypt(token))
}

// 设置用户信息和其他信息
export function setUserInfo(userInfo, other) {
  myUserInfo = userInfo
  otherData = Object.assign(otherData, other)
  Cookies.set(OtherKey, otherData)
  return Cookies.set(UserInfo, EDstr.Encrypt(userInfo))
}

// 设置打开的标签页
export function setViewTags(views) {
  const _newView = []
  views.forEach((item) => {
    _newView.push({
      title: item.title,
      path: item.path,
      fullPath: item.fullPath,
      name: item.name,
      query: item.query,
      meta: { ...item.meta }
    })
  })
  Cookies.set(viewTags, _newView)
}

// 删除 **********************************************

// 删除 token
export function removeToken() {
  myToken = ''
  otherData = {}
  Cookies.remove(OtherKey)
  return Cookies.remove(TokenKey)
}

// 删除用户信息、其他信息、打开的标签页
export function removeUserInfo() {
  myUserInfo = ''
  otherData = {}
  Cookies.remove(OtherKey)
  Cookies.remove(viewTags)
  return Cookies.remove(UserInfo)
}
