import { getToken, getOtherData, setToken, removeToken, getUserInfo, setUserInfo, removeUserInfo } from '@/utils/auth'
import { resetRouter } from '@/router'
import request from '@/utils/request'
import commonService from '@/utils/commonService'
import * as actionName from '@/utils/actionName'
import store from '../index'
import { clearAllCache } from '@/utils/request/cache'

const state = {
  token: getToken(),
  name: '',
  userInfo: getUserInfo(),
  avatar: '',
  roles: null,
  logoUrl: '',
  expireTime: '',
  isShowPay: '',
  groupName: ''
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_GROUPNAME: (state, name) => {
    state.groupName = name
  },
  SET_LOGOURL: (state, logoUrl) => {
    state.logoUrl = logoUrl
  },
  SET_USERINFO: (state, userInfo) => {
    state.userInfo = userInfo
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles
  },
  SET_EXPIRATION_TIME: (state, expireTime) => {
    state.expireTime = expireTime
  },
  SET_IS_SHOW_PAY: (state, isShowPay) => {
    state.isShowPay = isShowPay
  }
}

const actions = {
  // user login
  login({ commit, dispatch }, userInfo) {
    // 登录时清除缓存
    dispatch('resetToken')
    const requestLogin = commonService.formatCommonData(actionName.HXCS_QY_QYDL, userInfo)
    return new Promise((resolve, reject) => {
      request(requestLogin).then(response => {
        commit('SET_TOKEN', response.token)
        commit('SET_NAME', response.name)
        commit('SET_LOGOURL', response.logoUrl)
        commit('SET_USERINFO', response.companyId)
        commit('SET_EXPIRATION_TIME', response.expireTime)
        commit('SET_IS_SHOW_PAY', response.isShowPay)
        localStorage.setItem('expireTime', response.expireTime)
        localStorage.setItem('isShowPay', response.isShowPay)
        setToken(response.token, { name: response.name, logoUrl: response.logoUrl, userId: response.userId })
        // sendEmail 工单信息以邮件方式同步发出
        setUserInfo(response.companyId, { logoUrl: response.logoUrl, sendEmail: response.sendEmail })
        if (response.companyId) {
          dispatch('getUserInfoAny')
        }
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  // 服务商登录
  serviceProvidersLogin({ commit, dispatch }, userInfo) {
    // 登录时清除缓存
    dispatch('resetToken')
    const requestLogin = commonService.formatCommonData(actionName.HXCS_FW_FWDL, userInfo)
    return new Promise((resolve, reject) => {
      request(requestLogin).then(response => {
        commit('SET_TOKEN', response.token)
        commit('SET_GROUPNAME', response.groupName)
        commit('SET_USERINFO', response.companyId)
        commit('SET_LOGOURL', response.logoUrl)
        setToken(response.token, { groupName: response.groupName, groupId: response.groupId, logoUrl: response.logoUrl, userId: response.userId })
        // sendEmail 工单信息以邮件方式同步发出
        setUserInfo(response.companyId, { logoUrl: response.logoUrl, groupId: response.groupId, groupName: response.groupName, userType: 1 })
        if (response.companyId) {
          dispatch('getUserInfoAny')
        }
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },

  // user register
  register({ commit }, userInfo) {
    const requestLogin = commonService.formatCommonData(actionName.HXCS_QY_QYZC, userInfo)
    return new Promise((resolve, reject) => {
      request(requestLogin).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },

  // user getUserInfo
  getUserInfoAny() {
    const isUserInfo = getUserInfo()
    const requestLogin = commonService.formatCommonData(actionName.HXCS_QY_QYXX, {
      companyId: isUserInfo
    })
    return new Promise((resolve, reject) => {
      request(requestLogin).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },

  // 获取用户菜单信息
  getMenuList({ commit, state }) {
    const userData = getOtherData()
    if (userData && userData.userType === 1) {
      return new Promise((resolve) => {
        const menuList = [{
          name: '数据看板',
          icon: 'iconshouye',
          pid: '-1',
          type: '1',
          perms: '',
          sort: '1',
          id: 'shujukanban',
          label: '数据看板',
          value: 'shujukanban',
          url: '/dashboard',
          remark: '',
          children: [{
            name: '服务团队工作台',
            icon: '',
            pid: 'shujukanban',
            type: '2',
            perms: '',
            sort: '1',
            id: 'fuwushangwork',
            label: '服务团队工作台',
            value: 'fuwushangwork',
            url: 'engineerWorkBench',
            remark: '',
            children: []
          }]
        }, {
          name: '服务工单',
          icon: 'icongongdanguanli',
          pid: '-1',
          type: '1',
          perms: '',
          sort: '2',
          id: 'gongdanguanli',
          label: '服务工单',
          value: 'gongdanguanli',
          url: '/serviceWorkorderManagement',
          remark: '',
          children: []
        }, {
          name: '任务工单',
          icon: 'icongongdanguanli',
          pid: '-1',
          sort: '4',
          id: 'renwugongdan',
          type: '1',
          perms: '',
          label: '任务工单',
          value: 'xinxiweihu',
          url: '/serviceTaskOrderManage',
          remark: '',
          children: []
        }, {
          name: '人员管理',
          icon: 'iconrenyuanguanli',
          pid: '-1',
          sort: '2',
          id: 'renyuanguanli',
          type: '1',
          perms: '',
          label: '人员管理',
          value: 'renyuanguanli',
          url: '/serviceUserManage',
          remark: '',
          children: []
        }, {
          name: '信息维护',
          icon: 'iconzhishiku',
          pid: '-1',
          sort: '3',
          id: 'xinxiweihu',
          type: '1',
          perms: '',
          label: '信息维护',
          value: 'xinxiweihu',
          url: '/informationMaintain',
          remark: '',
          children: []
        }]
        commit('SET_AVATAR', 'https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif')
        resolve(menuList)
      })
    }
    const requestMenuList = commonService.formatCommonData(actionName.HXCS_QY_DLCD)
    return new Promise((resolve, reject) => {
      request(requestMenuList).then(response => {
        if (!response) {
          reject('Verification failed, please Login again.')
        }
        const other = getOtherData()
        commit('SET_NAME', other.name)
        commit('SET_LOGOURL', other.logoUrl)
        commit('SET_AVATAR', 'https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif')
        commit('SET_ROLES', response)
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },

  // user logout
  logout({ dispatch, commit }) {
    const requestLoginOut = commonService.formatCommonData(actionName.HXCS_QY_TCDL)
    return new Promise((resolve, reject) => {
      request(requestLoginOut).then(() => {
        resolve()
      }).catch(error => {
        reject(error)
      })
      dispatch('resetToken')
    })
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '')
      commit('SET_ROLES', null)
      commit('SET_NAME', '')
      commit('SET_LOGOURL', '')
      commit('SET_USERINFO', '')
      commit('SET_GROUPNAME', '')
      store.dispatch('permission/generateRoutes', [])
      clearAllCache()
      resetRouter()
      removeToken()
      removeUserInfo()
      resolve()
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

