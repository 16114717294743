/**
 * 生成UUID
 * @returns {string}
 */
function generateUUID() {
  const uuid = 'xxxxxxxxxxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    const r = Math.random() * 16 | 0
    const v = c === 'x' ? r : (r & 0x3 | 0x8)
    return v.toString(16)
  })
  return uuid
}

/**
 * 生成UUID图片
 * @returns {string}
 */
function formatImage(name) {
  return new Date().getTime() + name.substring(name.lastIndexOf('.'))
  // return generateUUID() + name.substring(name.lastIndexOf('.'))
}

/**
 * 文件名架后缀
 * @param name
 * @param addName
 * @returns {string}
 */
function addFormatImage(name, addName) {
  const lastNum = name.lastIndexOf('.')
  return name.substring(0, lastNum) + '_' + addName + name.substring(lastNum)
}

/**
 * 图片名称修改
 * @param url
 * @returns {string}
 */
function formatSubImg(url) {
  let finnalUrl
  if (url.lastIndexOf('?') !== -1) {
    finnalUrl = url.substring(0, url.lastIndexOf('?'))
  } else {
    finnalUrl = url
  }
  return finnalUrl
}

export default {
  generateUUID,
  addFormatImage,
  formatImage,
  formatSubImg
}
