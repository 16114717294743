var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "sidebar-logo-container",
      class: { collapse: _vm.collapse }
    },
    [
      _vm.collapse
        ? _c("div", { key: "collapse", staticClass: "sidebar-logo-link" }, [
            _vm.logo
              ? _c("img", {
                  staticClass: "sidebar-logo",
                  attrs: { src: _vm.logoUrl ? _vm.logoUrl : _vm.logo }
                })
              : _vm._e()
          ])
        : _c("div", { key: "expand", staticClass: "sidebar-logo-link" }, [
            _vm.logo
              ? _c("img", {
                  staticClass: "sidebar-logo",
                  attrs: { src: _vm.logoUrl ? _vm.logoUrl : _vm.logo }
                })
              : _vm._e()
          ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }