import { render, staticRenderFns } from "./upimage.vue?vue&type=template&id=c3121c70&"
import script from "./upimage.vue?vue&type=script&lang=js&"
export * from "./upimage.vue?vue&type=script&lang=js&"
import style0 from "./upimage.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\阎梦娇\\Desktop\\优海项目\\uhi-company-web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('c3121c70')) {
      api.createRecord('c3121c70', component.options)
    } else {
      api.reload('c3121c70', component.options)
    }
    module.hot.accept("./upimage.vue?vue&type=template&id=c3121c70&", function () {
      api.rerender('c3121c70', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/GlobalUploadImage/upimage.vue"
export default component.exports