<template>
  <div style="padding: 0 15px 0 0;" @click="toggleClick">
    <svg-icon
      :class="{'is-active':isActive}"
      class="hamburger"
      icon-class="icondaohangzhankai"
    />
  </div>
</template>

<script>
  export default {
    name: 'Hamburger',
    props: {
      isActive: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      toggleClick() {
        this.$emit('toggleClick')
      }
    }
  }
</script>

<style scoped>
  .hamburger {
    display: inline-block;
    vertical-align: middle;
  }
  .hamburger.svg-icon {
    width: 1em;
    height: 1em;
  }
  .hamburger.is-active {
    transform: rotate(180deg);
  }
</style>
