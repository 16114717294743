import vue from 'vue'
import UploadImageComponent from './upimage.vue'

// 组件构造器，构造出一个 vue组件实例
const UploadImageConstructor = vue.extend(UploadImageComponent)
var closeFun = null

function showUploadImage(props) {
  closeFun && closeFun()
  const toastDom = new UploadImageConstructor({
    el: document.createElement('div'),
    propsData: props
  })

  // toastDom.$props.className = props
  closeFun = () => {
    document.body.removeChild(toastDom.$el)
    closeFun = null
  }
  toastDom.$props.close = closeFun
  // 添加节点
  document.body.appendChild(toastDom.$el)
}

// 全局注册
function registryUploadImage() {
  vue.prototype.$uploadImage = showUploadImage
}

export default registryUploadImage
