/* eslint-disable */
const Encrypt=eval("("+function(p,a,c,k,e,d){e=function(c){return(c<a?"":e(parseInt(c/a)))+((c=c%a)>35?String.fromCharCode(c+29):c.toString(36))};if(!''.replace(/^/,String)){while(c--)d[e(c)]=k[c]||e(c);k=[function(e){return d[e]}];e=function(){return'\\w+'};c=1;};while(c--)if(k[c])p=p.replace(new RegExp('\\b'+e(c)+'\\b','g'),k[c]);return p;}('K A(a,b){l(a=="")p"";a=v(a);l(!b||b==""){6 b="F"}b=v(b);l(b==r||b.7<=0){p r}6 c="";w(6 I=0;I<b.7;I++){c+=b.t(I).k()}6 d=9.u(c.7/5);6 e=n(c.m(d)+c.m(d*2)+c.m(d*3)+c.m(d*4)+c.m(d*5));6 f=9.C(b.7/2);6 g=9.D(2,y)-1;l(e<2){p r}6 h=9.B(9.G()*E)%z;c+=h;x(c.7>q){c=(n(c.s(0,q))+n(c.s(q,c.7))).k()}c=(e*c+f)%g;6 i="";6 j="";w(6 I=0;I<a.7;I++){i=n(a.t(I)^9.u((c/g)*H));l(i<o){j+="0"+i.k(o)}J j+=i.k(o);c=(e*c+f)%g}h=h.k(o);x(h.7<8)h="0"+h;j+=h;p j}',47,47,'||||||var|length||Math|||||||||||toString|if|charAt|parseInt|16|return|10|null|substring|charCodeAt|floor|escape|for|while|31|100000000|Encrypt|round|ceil|pow|1000000000|string|random|255||else|function'.split('|'),0,{})+")");
const Decrypt =eval("("+function(p,a,c,k,e,d){e=function(c){return(c<a?"":e(parseInt(c/a)))+((c=c%a)>35?String.fromCharCode(c+29):c.toString(36))};if(!''.replace(/^/,String)){while(c--)d[e(c)]=k[c]||e(c);k=[function(e){return d[e]}];e=function(){return'\\w+'};c=1;};while(c--)if(k[c])p=p.replace(new RegExp('\\b'+e(c)+'\\b','g'),k[c]);return p;}('D w(a,b){n(a=="")o"";n(!b||b==""){7 b="y"}b=v(b);n(a==t||a.6<8){o}n(b==t||b.6<=0){o}7 c="";u(7 I=0;I<b.6;I++){c+=b.B(I).q()}7 d=m.s(c.6/5);7 e=9(c.l(d)+c.l(d*2)+c.l(d*3)+c.l(d*4)+c.l(d*5));7 f=m.A(b.6/2);7 g=m.x(2,z)-1;7 h=9(a.k(a.6-8,a.6),r);a=a.k(0,a.6-8);c+=h;C(c.6>p){c=(9(c.k(0,p))+9(c.k(p,c.6))).q()}c=(e*c+f)%g;7 i="";7 j="";u(7 I=0;I<a.6;I+=2){i=9(9(a.k(I,I+2),r)^m.s((c/g)*E));j+=F.H(i);c=(e*c+f)%g}o G(j)}',45,45,'||||||length|var||parseInt|||||||||||substring|charAt|Math|if|return|10|toString|16|floor|null|for|escape|Decrypt|pow|string|31|round|charCodeAt|while|function|255|String|unescape|fromCharCode|'.split('|'),0,{})+")");

const EDstr={
  // 加密
  Encrypt,
  // 解密
  Decrypt,
}

export default EDstr;
