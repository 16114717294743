const OSS = require('ali-oss')
import commonService from './commonService'
import request from './request'
import * as actionName from './actionName'
import { getUserInfo } from '@/utils/auth'

let ossToken = {
  accessKeyId: '',
  accessKeySecret: '',
  stsToken: '',
  region: '',
  bucket: '',
  outTime: new Date().getTime()
}
// 等待上传的数组队列
const waitingUpload = []
// 是否正在上传中的标记
let isUploading = false
// 上传的类型
let upType = ''
// 上传的地址
let dir = ''

// 向队列数组里添加数据
function addQueue(data) {
  waitingUpload.push(data)
  if (!isUploading) {
    doQueue()
  }
}

// 处理队列
function doQueue() {
  const queueLen = waitingUpload.length
  if (queueLen > 0) {
    isUploading = true
    const { upData, result, uploadProgress } = waitingUpload.shift()
    if (Array.isArray(upData)) {
      // 上传队列数据。
      uploadArray(upData, (r) => {
        result && result(r)
        doQueue()
      }, uploadProgress)
    } else {
      // 上传单条数据
      uploadArray([upData], (r) => {
        result && result(r)
        doQueue()
      }, uploadProgress)
    }
  } else {
    isUploading = false
  }
}

// 上传啊！！！
function uploadArray(arrayData, callback, uploadProgress) {
  const num = arrayData.length
  const s = {
    success: true,
    successData: [],
    badData: []
  }

  function queueing(iNum) {
    const i = iNum
    if (i < num) {
      const { fileName, file, type, ...other } = arrayData[i]
      const expired = new Date().getTime() > ossToken.outTime
      if (type !== upType || expired) {
        getDir(type, expired, (backDir) => {
          upType = type
          dir = backDir
          const filePath = backDir + fileName
          _upload(i, filePath, fileName, file, other)
        })
      } else {
        const filePath = dir + fileName
        _upload(i, filePath, fileName, file, other)
      }
    } else {
      callback && callback(s)
    }
  }

  function _upload(i, filePath, fileName, file, other) {
    let _i = i
    upload(filePath, file, (data, e) => {
      if (data) {
        s.successData.push({ fileName, ...data, ...other })
      } else {
        s.success = false
        s.badData.push({ fileName, error: e, ...other })
      }
      _i++
      queueing(_i)
    }, (a) => {
      uploadProgress && uploadProgress((_i + a) / num)
    })
  }

  queueing(0)
}

/**
 * 单个上传的方法
 * @param fileNames
 * @param files
 * @param callback
 * @param uploadProgress
 */
function upload(fileNames, files, callback, uploadProgress) {
  client(ossToken).multipartUpload(fileNames, files, { progress: uploadProgress }).then(result => {
    callback(result)
  }).catch((e) => {
    console.log('wwwlin-shangchuan cuowu ', e)
    callback(false, e)
  })
}

/**
 * 获取 dir，并判断 ossToken 是否过期，过期重新获取
 * @param type
 * @param expired
 * @param callback
 */
const companyId = getUserInfo()
function getDir(type, expired, callback) {
  const uploadUrl = companyId + '/' + type
  const requestMenu = commonService.formatCommonData(actionName.HXCS_QY_OSST, {
    uploadType: uploadUrl,
    accessToken: expired
  })
  request(requestMenu).then((response) => {
    const { dir, ...oter } = response
    if (expired) {
      ossToken = {
        ...oter,
        outTime: new Date().getTime() + (700 * 1000)
      }
    }
    callback(dir)
  })
}

/**
 * 上传文件方法
 * @param upData 要上传的数据  可以是对象 也可以是数组 结构是这样如下
 * [{
 *    fileName:'xxx.jpg',  // (必须)文件名字，提前做 UUID的更改，避免重复导致覆盖
 *    file: xxx,           // (必须)上传的文件
 *    type: 'img',         // (必须)用于区分上传文件夹的类型
 *    otherData:{}         // 其他参数
 * }]
 * @param result  返回数据结果的方法 返回的结构如下
 * {
 *  success: true,        // 上传是否成功
 *  successData: [],      // 上传正确的数组
 *  badData: [{           // 上传错误的数组
 *    name:'错误的文件名'，
 *    error:'错误信息'
 *  }]
 * }
 * @param uploadProgress 返回进度状态0-1 之间的方法
 */
export function uploadFile(upData, result, uploadProgress) {
  addQueue({ upData, result, uploadProgress })
}

export function client(data) {
  return new OSS({
    accessKeyId: data.accessKeyId,
    accessKeySecret: data.accessKeySecret,
    stsToken: data.securityToken,
    region: data.region, // oss地区
    bucket: data.bucket
  })
}
