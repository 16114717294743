exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n", ""]);

// exports
exports.locals = {
	"menuText": "#333",
	"menuActiveText": "#5584FF",
	"subMenuActiveText": "#5584FF",
	"menuBg": "#fff",
	"menuHover": "rgba(85,132,255,0.1)",
	"subMenuBg": "#fff",
	"subMenuHover": "rgba(85,132,255,0.1)",
	"sideBarWidth": "200px",
	"primaryButtonBg": "#5584ff",
	"primaryButtonHoverBg": "#3F72F7",
	"tableHeaderBg": "#FAFAFA",
	"appMainBg": "#FAFAFA",
	"mainBg": "#5584ff",
	"grayTextColor": "#909399",
	"grayDeepTextColor": "#606266",
	"tagViewBg": "rgba(85,132,255,0.1)",
	"tagViewText": "#5584ff",
	"breadLine": "#333",
	"lineIco": "#666",
	"margin4": "4px"
};