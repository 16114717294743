import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
import store from '@/store'
import { getToken } from '../auth'
import cache from './cache'
import i18n from '@/lang'
import pako from 'pako'
import { decode, encode } from 'base-64'

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 300000, // request timeout
  transformRequest: [
    // 复用原有的转换，实现json --> json string
    axios.defaults.transformRequest[0],
    (data, header) => {
      // 如果已处理过数据，则跳过
      // if (!header['Content-Encoding']) {
      //   console.log('wwwlin-ss???', data)
      //   return data
      // }
      // 如果数据长度1KB（如字符数据并不一定小于1KB），不压缩
      // if (data.length < 1024) {
      //   return data;
      // }
      // 将数据压缩（可根据需要，只压缩长度大于多少的数据）
      // 设置数据类型
      header['Post-Data-Type'] = 'gzip'
      header['Return-Data-Type'] = 'gzip'
      header['Content-Type'] = 'application/json;charset=UTF-8'
      return encode(pako.gzip(data, { to: 'string' }))
    }
  ]

})

// request interceptor请求拦截器
service.interceptors.request.use(
  config => {
    // do something before request is sent

    if (store.getters.token) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      config.headers['X-Token'] = getToken()

      // config.headers['Post-Data-Type'] = 'gzip'// 请求数据为gzip
      // config.headers['Return-Data-Type'] = 'gzip'// 请求数据为gzip
      // config.headers['Content-Type'] = 'application/json;charset=UTF-8'// 请求数据为gzip
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    // const res = response.data
    let responseData = decode(response.data)
    responseData = responseData.split('').map(function(x) { return x.charCodeAt(0) })
    responseData = JSON.parse(pako.inflate(new Uint8Array(responseData), { to: 'string' }))
    const res = responseData.body
    // console.log('responseData', responseData, res)
    const { isSuccessful } = res
    if (isSuccessful === undefined || !isSuccessful) {
      // if the custom code is not 20000, it is judged as an error.
      Message({
        message: res.errorMsg || 'Error',
        type: 'error',
        duration: 5 * 1000
      })
      if (res.errorCode === 'customerNotLogined' || res.errorCode === 'tokenFailure') {
        store.dispatch('user/resetToken').then(() => {
          store.dispatch('tagsView/delAllViews')
          location.reload()
        })
        MessageBox.confirm(i18n.t('errorLog.loggedOut'), i18n.t('errorLog.confirmLogout'), {
          confirmButtonText: i18n.t('errorLog.reLogin'),
          cancelButtonText: i18n.t('errorLog.cancel'),
          type: 'warning'
        }).then(() => {
          store.dispatch('user/resetToken').then(() => {
            store.dispatch('tagsView/delAllViews')
            location.reload()
          })
        })
      }
      var error = new Error(res.errorMsg || 'Error')
      error.errorCode = res.errorCode
      return Promise.reject(error)
      // return Promise.reject(new Error(res.errorMsg || 'Error'))
    } else {
      return res.resultData
    }
  },
  error => {
    console.log('err' + error) // for debug
    Message({
      message: error.data.body.errorMsg,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(new Error(error.data.body.errorMsg || 'Error'))
  }
)

async function request(param) {
  return cache(param, service)
}

export default request
