import defaultSettings from '@/settings'
import i18n from '@/lang'

const title = defaultSettings.title || '大象企服'

export default function getPageTitle(key) {
  if (key) {
    const hasKey = i18n.te(`route.${key}`)
    if (hasKey) {
      const pageName = i18n.t(`route.${key}`)
      return `${pageName} - ${title}`
    }
    return `${key} - ${title}`
  } else {
    return `${title}`
  }
}
