export default {
  route: {
    dashboard: '首页',
    login: '登录',
    // 自定义
    PermissSettings: '管理员相关设置',
    MainRoleManage: '角色管理',
    MainUserManage: '用户管理',
    MainManage: '主站管理',
    MainMenuManage: '菜单管理',
    OperatSettings: '运行参数设置',
    MsgSettings: '短信设置',
    EmailSettings: '邮件设置',
    Enterprise: '企业管理',
    EnterpriseManage: '企业级别管理',
    EnterpriseMenuManage: '企业菜单管理',
    Extension: '首页推广图',
    CarouselMapManage: '首页轮播图',
    FloatingBtnManage: '首页悬浮按钮',
    PropertyInfo: '地产良仓',
    ShopList: '店铺列表',
    ShopType: '店铺类型',
    Market: '商场',
    Life: '生活+',
    MarketList: '商场管理',
    DiscountCoupon: '优惠券',
    ActiveInfo: '活动',
    ActiveInfoEdit: '编辑活动',
    ActiveInfoDetail: '活动详情',
    YkhmemberList: '大象企服用户',
    ActivememberList: '活动人员列表',
    MarketFloorList: '楼层信息',
    AdmissionTicket: '入场券列表信息',
    ReceivedUserList: '已领取用户列表',
    HighQualityLife: '品质生活列表',
    HomeEventPromotion: '活动首页推广图',
    HomePage: '首页',
    Coupon: '券管理',
    MemberList: '会员管理',
    JOY: '生活+',
    ActiveCarouselMap: '活动轮播图'
  },
  navbar: {
    dashboard: '首页',
    github: '项目地址',
    logOut: '退出登录',
    profile: '个人中心',
    theme: '换肤',
    size: '布局大小'
  },
  login: {
    title: '系统登录',
    logIn: '登录',
    username: '请输入账号',
    password: '请输入密码',
    any: '随便填',
    thirdparty: '第三方登录'
  },
  documentation: {
    documentation: '文档',
    github: 'Github 地址'
  },
  permission: {
    addRole: '新增角色',
    editPermission: '编辑权限',
    roles: '你的权限',
    switchRoles: '切换权限',
    tips: '在某些情况下，不适合使用 v-permission。例如：Element-UI 的 el-tab 或 el-table-column 以及其它动态渲染 dom 的场景。你只能通过手动设置 v-if 来实现。',
    delete: '删除',
    confirm: '确定',
    cancel: '取消'
  },
  guide: {
    description: '引导页对于一些第一次进入项目的人很有用，你可以简单介绍下项目的功能。本 Demo 是基于',
    button: '打开引导',
    help: '帮助'
  },
  components: {},
  table: {
    dynamicTips1: '固定表头, 按照表头顺序排序',
    dynamicTips2: '不固定表头, 按照点击顺序排序',
    dragTips1: '默认顺序',
    dragTips2: '拖拽后顺序',
    title: '标题',
    username: '用户名',
    nickname: '昵称',
    phoneNumber: '手机号',
    password: '密码',
    confirmPassword: '确认密码',
    pleaseEnter: '请输入',
    importance: '重要性',
    type: '类型',
    remark: '点评',
    search: '搜索',
    add: '添加',
    create: '添加',
    export: '导出',
    exportExcel: '导出Execl',
    reviewer: '审核人',
    id: '序号',
    date: '时间',
    author: '作者',
    readings: '阅读数',
    status: '状态',
    actions: '操作',
    edit: '编辑',
    close: '关闭',
    detail: '详情',
    publish: '发布',
    draft: '草稿',
    delete: '删除',
    cancel: '取 消',
    confirm: '确 定',
    save: '保存',
    roleConfig: '角色配置',
    moveUp: '上移',
    moveDown: '下移',
    bulid: '新建'
  },
  errorLog: {
    tips: '请点击右上角bug小图标',
    description: '现在的管理后台基本都是spa的形式了，它增强了用户体验，但同时也会增加页面出问题的可能性，可能一个小小的疏忽就导致整个页面的死锁。好在 Vue 官网提供了一个方法来捕获处理异常，你可以在其中进行错误处理或者异常上报。',
    documentation: '文档介绍',
    noCorrectUserName: '请输入正确的用户名',
    password6len: '密码至少为6位',
    reLogin: '重新登录',
    prompt: '提示',
    deleteUser: '将删除该用户, 是否继续?',
    confirm: '确认',
    cancel: '取消',
    loggedOut: '你的账号已经登出，您可以取消并停留这个页面，或者重新登录。',
    confirmLogout: '确认登出'
  },
  excel: {
    export: '导出',
    selectedExport: '导出已选择项',
    placeholder: '请输入文件名(默认excel-list)'
  },
  zip: {
    export: '导出',
    placeholder: '请输入文件名(默认file)'
  },
  pdf: {
    tips: '这里使用   window.print() 来实现下载pdf的功能'
  },
  theme: {
    change: '换肤',
    documentation: '换肤文档',
    tips: 'Tips: 它区别于 navbar 上的 theme-pick, 是两种不同的换肤方法，各自有不同的应用场景，具体请参考文档。'
  },
  tagsView: {
    refresh: '刷新',
    close: '关闭',
    closeOthers: '关闭其它',
    closeAll: '关闭所有'
  },
  settings: {
    title: '系统布局配置',
    theme: '主题色',
    tagsView: '开启 Tags-View',
    fixedHeader: '固定 Header',
    sidebarLogo: '侧边栏 Logo'
  },
  message: {
    langSelectOK: '切换语言成功',
    success: '成功',
    failed: '失败',
    checkInfo: '请检查填写信息是否正确，必填项是否填写',
    editSuccessful: '编辑成功',
    deleteFailed: '删除失败',
    deleteSuccessful: '删除成功',
    deleteCancel: '取消删除',
    operateSuccessful: '操作成功',
    dotDeleteUser: '此用户不允许删除',
    enterCorrectPhoneNum: '请输入正确的手机号码',
    enterPhoneNum: '请输入手机号码',
    enterUsername: '请输入用户名',
    enterNickname: '请输入昵称',
    enterPassword: '请输入密码',
    enterconfirmPassword: '请输入确认密码',
    inconsistentPassword: '密码不一致'
  },
  err404: {
    oops: '哎呀！',
    notEnterPage: '管理员说您无法打开这个页面...',
    checkURL: '请检查您输入的URL是否正确，或单击下面的按钮返回主页。',
    closePage: '单击下面的按钮关闭这个页面',
    backHome: '回到主页',
    close: '关闭'
  }
}
