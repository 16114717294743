<template>
  <section class="app-main">
    <!--    <transition name="fade-transform" mode="out-in">-->
    <keep-alive :exclude="excludeViews">
      <router-view ref="keepAlive" :key="key" />
    </keep-alive>
    <!--    </transition>-->
  </section>
</template>

<script>
  export default {
    name: 'AppMain',
    computed: {
      excludeViews() {
        return this.$store.state.tagsView.excludeViews
      },
      key() {
        return this.$route.path
      }
    },
    mounted() {
      // 当页面渲染完成后 router 再 vnode 下 就可以拿到 vnode 了，把他放到页面缓存处理那里来做缓存的处理
      this.$store.dispatch('tagsView/setCache', this.$refs['keepAlive'].$vnode.parent.componentInstance)
    }
  }
</script>

<style lang="scss" scoped>
  @import '../../styles/variables.scss';
  .app-main {
    /* 50= navbar  50  */
    /*min-height: calc(100vh - 50px);*/
    width: 100%;
    position: relative;
    overflow: hidden;
  }

  .fixed-header + .app-main {
    /*padding-top: 50px;*/
    /*height: 100vh;*/
    height: calc(100vh - 110px);
    /*overflow: auto;*/
  }

  .hasTagsView {
    .app-main {
      /* 84 = navbar + tags-view = 50 + 34 */
      /*min-height: calc(100vh - 84px);*/
    }

    .fixed-header + .app-main {
      /*padding-top: 84px;*/
    }
  }
</style>

<!--<style lang="scss">-->
<!--  // fix css style bug in open el-dialog-->
<!--  .el-popup-parent&#45;&#45;hidden {-->
<!--    .fixed-header {-->
<!--      padding-right: 15px;-->
<!--    }-->
<!--  }-->
<!--</style>-->
